import message from "../../../assets/icons/message.svg";
import { Button, ErrorMessage, Input } from "../../../components";
import { Form, Formik } from "formik";
import {
  forgotPasswordFormType,
  forgotPasswordYupSchema,
} from "../../../utils/validation";
import { FORGOT_PASSWORD_PAGE } from "../../../constants/auth-pages.constants";
import { forgotPasswordSupabase } from "../utils/authHelpers";
import { handleErrors } from "../../../utils/handleErrors";
import { root } from "../styles";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { SNACKBAR_MESSAGES } from "../../../constants/global.constants";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useTimer } from "../../../utils/hooks/useTimer";

/** Renders and handle the Forgot password form */
export const ForgotPasswordForm = () => {
  const initialValues = {
    email: "",
    generalErr: "",
  };

  const [isResend, setIsResend] = useState(false);
  const { minutes, seconds, resetTimer } = useTimer({
    initialMinutes: 1,
    initialSeconds: 59,
  });
  const { setSnackBarState } = useSnackbarContext();

  const handleSubmit = async (
    values: forgotPasswordFormType,
    { setSubmitting, setErrors }: any
  ) => {
    try {
      setSubmitting(true);
      await forgotPasswordSupabase(values);
      setSnackBarState((prev) => ({
        ...prev,
        isOpen: true,
        message: SNACKBAR_MESSAGES.RESET_PASSWORD_EMAIL_SENT,
      }));
      setSubmitting(false);
      setIsResend(true);
      resetTimer();
    } catch (e) {
      const err = handleErrors(e);
      setErrors({ generalErr: err.message });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordYupSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form css={root(isSubmitting)}>
          <Input
            Icon={<img src={message} />}
            placeholder={FORGOT_PASSWORD_PAGE.NAME_FIELD_PLACEHOLDER}
            id="email"
            name="email"
          />

          <ErrorMessage name="generalErr" />

          <Stack direction={"row"} gap={2}>
            <Button
              button="CTA"
              type="submit"
              disabled={
                isSubmitting || ((seconds > 0 || minutes > 0) && isResend)
              }
            >
              {isResend ? (
                <>
                  {FORGOT_PASSWORD_PAGE.RESEND_BUTTON_TEXT}{" "}
                  {seconds > 0 || minutes > 0 ? `(${minutes}:${seconds})` : ""}
                </>
              ) : (
                FORGOT_PASSWORD_PAGE.CTA_BUTTON_TEXT
              )}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
