import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { router } from "./config/router";
import { ThemeProvider } from "@emotion/react";
import { theme, globalStyles } from "./config/theme";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { SnackbarContextProvider } from "./context/SnackbarContext";
import { Snackbar } from "./components";
import { queryClient } from "./config/reactQuery.config";
import { Provider as RollbarProvider } from "@rollbar/react";
import { rollbarConfig } from "./config/rollbar.config";

// Initialize analytics if env is prod
// if (import.meta.env.VITE_ENVIRONMENT === ENV_OPTIONS.PROD) {
//   const app = initializeApp(firebaseConfig);
//   getAnalytics(app);
// }

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RollbarProvider config={rollbarConfig}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarContextProvider>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <RouterProvider router={router} />
          <Snackbar />
        </SnackbarContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </RollbarProvider>
);
