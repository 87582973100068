import { css } from "@emotion/react";
import { Grid, Typography } from "@mui/material";
import { useGetFixtures } from "../../../utils/hooks/useGetFixtures";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { bgColor } from "../../../config/theme";
import { transition_all } from "../../../config/styles";
import { Skeleton } from "../../../components";

interface IEplPlayerFixtureSummary {
  fixtureId: number | null;
}

/** Renders the Fixture summary displayed in player summary modal */
export const EplPlayerFixtureSummary = (props: IEplPlayerFixtureSummary) => {
  const { getFixtureResultsOverviewPath } = useGetUrl();
  const navigate = useNavigateState();
  const { fixturesData } = useGetFixtures();

  const eplPlayerFixture = fixturesData?.fixtures.find(
    (i) => i.id === props.fixtureId
  );

  const handleFixtureClick = () => {
    if (eplPlayerFixture) {
      navigate(getFixtureResultsOverviewPath(eplPlayerFixture.id));
    }
  };

  if (!eplPlayerFixture) {
    return <EplPlayerFixtureSummarySkeleton />;
  }

  return (
    <Grid
      container
      css={middleSectionContainerStyles}
      onClick={handleFixtureClick}
    >
      <Grid item xs>
        <Typography css={[teamNameStyles, homeTeamNameStyles]}>
          {eplPlayerFixture.team_h_name}
        </Typography>
      </Grid>
      <Grid item>
        <img src={eplPlayerFixture.team_h_logo} />
      </Grid>
      <Grid item xs>
        <Typography css={liveScoresStyle}>
          <span>{eplPlayerFixture.team_h_score}</span>
          <span>:</span>
          <span>{eplPlayerFixture.team_a_score}</span>
        </Typography>
      </Grid>
      <Grid item>
        <img src={eplPlayerFixture.team_a_logo} />
      </Grid>
      <Grid item xs>
        <Typography css={teamNameStyles}>
          {eplPlayerFixture.team_a_name}
        </Typography>
      </Grid>
    </Grid>
  );
};

const EplPlayerFixtureSummarySkeleton = () => (
  <Grid container css={middleSectionContainerStyles}>
    <Grid item xs>
      <Typography css={[teamNameStyles, homeTeamNameStyles]}>
        <Skeleton
          css={css`
            background-color: #1c21307b;
            width: 80px;
          `}
        />
      </Typography>
    </Grid>
    <Grid item>
      <Skeleton
        css={css`
          background-color: #1c21307b;
          width: 50px;
          aspect-ratio: 1/1;
          border-radius: 9999px;
        `}
      />
    </Grid>
    <Grid item xs>
      <Typography
        css={[
          liveScoresStyle,
          css`
            opacity: 0.3;
          `,
        ]}
      >
        <span>0</span>
        <span>:</span>
        <span>0</span>
      </Typography>
    </Grid>
    <Grid item>
      <Skeleton
        css={css`
          background-color: #1c21307b;
          width: 50px;
          aspect-ratio: 1/1;
          border-radius: 9999px;
        `}
      />
    </Grid>
    <Grid item xs>
      <Typography css={teamNameStyles}>
        <Skeleton
          css={css`
            background-color: #1c21307b;
            width: 80px;
          `}
        />
      </Typography>
    </Grid>
  </Grid>
);

const liveScoresStyle = css`
  font-weight: 900;
  font-size: clamp(1rem, 4vw, 2rem);
  white-space: nowrap;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
`;

const middleSectionContainerStyles = css`
  ${transition_all}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  padding: 0.5rem 0.8rem;
  border-radius: 1rem;

  img {
    width: clamp(2.5rem, 9vw, 3.2rem);
    aspect-ratio: 1/1;
  }

  * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  background-color: ${bgColor}99;

  :hover {
    background-color: ${bgColor};
  }
`;

const teamNameStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: clamp(0.875rem, 2vw, 1.5rem);
`;

const homeTeamNameStyles = css`
  text-align: right;
`;
