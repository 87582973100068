import { useLocation, useNavigate, NavigateOptions } from "react-router-dom";
import { ENV_OPTIONS } from "../../config/app.config";

/**
 * Custom hook for navigation that includes the current location state in Dev environment.
 * This helps in debugging routing
 * @returns {Function} - React-router Navigate function with the location object added to the state {debugState: location} in dev only, retunr the react router navigate function in prod
 */

export const useNavigateState = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateState = (pathName: string, options?: NavigateOptions) => {
    const pathHistory =
      location.state && location.state.debugStates
        ? location.state.debugState.pathHistory
        : [];

    navigate(pathName, {
      ...options,
      state: {
        debugState: {
          pathHistory: [...pathHistory, location.pathname],
        },
        ...options?.state,
      },
    });
  };

  return import.meta.env.VITE_ENVIRONMENT === ENV_OPTIONS.LOCAL
    ? navigateState
    : navigate;
};
