import { IconButton, Modal, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import warningIcon from "../assets/icons/warning.svg";
import { bgColor } from "../config/theme";
import { Button } from ".";
import { ERROR_MODAL_CONTENT } from "../constants/global.constants";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface IErrorModal {
  title?: string;
  message?: string;
  isOpen: boolean;
  onCloseIconButton?: () => void;
  onCloseClick?: () => void;
  buttons?: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * ErrorModal component displays a modal dialog containing an error message.
 * Has a default cancel button pass onCloseClick function
 * Accepts buttons component
 * Accepts a chidden to render
 *
 * @param {IErrorModal} props - Component properties
 * @param {string} props.message - The error message to be displayed within the modal.
 *
 */

export const ErrorModal = (props: IErrorModal) => {
  return (
    <Modal open={props.isOpen}>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        height={"100vh"}
        p={4}
      >
        <Stack css={rootStyles}>
          {props.onCloseIconButton && (
            <IconButton
              css={closeButtonStyles}
              onClick={props.onCloseIconButton}
            >
              <CloseRoundedIcon />
            </IconButton>
          )}
          <Stack alignItems={"center"} justifyContent={"center"} gap={2}>
            <img src={warningIcon} alt="waring" />
            <Typography variant="h1" fontSize={16} textAlign={"center"}>
              {props.title || ERROR_MODAL_CONTENT.TITLE}
            </Typography>
          </Stack>

          {/* Message */}
          {props.message && (
            <Typography textAlign={"center"} fontSize={14}>
              {props.message}
            </Typography>
          )}

          {props.children}

          {/* Default close button */}
          {props.onCloseClick && (
            <Button
              onClick={props.onCloseClick}
              css={buttonStyle}
              button="gradient"
            >
              {ERROR_MODAL_CONTENT.CLOSE_BUTTON_TITLE}
            </Button>
          )}

          {/* Custom Buttons */}
          {props.buttons}
        </Stack>
      </Stack>
    </Modal>
  );
};

const rootStyles = css`
  position: relative;
  padding: 1.5rem;

  background-color: ${bgColor};
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 555px;
  border-radius: 1.5rem;
  height: fit-content;
`;

const buttonStyle = css`
  height: 41px;
`;

const closeButtonStyles = css`
  position: absolute;
  top: 1rem;
  right: 1rem;

  border: 1px solid #ffffff44;

  * {
    color: white;
  }
`;
