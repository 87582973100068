import React, { ReactNode, useState } from "react";
import { Input } from "./Input";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { css } from "@emotion/react";
import { Skeleton } from "./Skeleton";
import { bgColor } from "../config/theme";
import { Stack } from "@mui/material";
import { transition_all } from "../config/styles";
import { TEAM_DETAIL_PAGE } from "../constants/auth-pages.constants";

interface IAutoCompleteInput<T> {
  icon?: ReactNode;
  placeholder: string;
  id: string;
  name: string;
  resultsArray: T[] | null;
  onAutoCompleteChange: (inputValue: string) => void;
  onItemClick: (item: T) => void;
  renderItem?: (item: T) => React.ReactNode;
  renderNotFound?: React.ReactNode;
}

/**
 * Auto complete component
 * Show results box if input felid is nopt empty
 * Show loading state if results Array is empty
 *
 * Render Item passes item and expects ReactNode to be returned
 * If no render item is provided results array must be a string[] to render correctly
 */

export const AutoCompleteInput = <T,>(props: IAutoCompleteInput<T>) => {
  const { placeholder, id, name } = props;
  const [showResultsContainer, setShowResultsContainer] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      props.onAutoCompleteChange(e.target.value);
      setInputValue(e.target.value);
      setShowResultsContainer(true);
    } else {
      setInputValue("");
      setShowResultsContainer(false);
    }
  };

  const handleInputClick = () => {
    if (inputValue) {
      setShowResultsContainer(true);
    }
  };

  const handleOnItemClick = (item: T) => {
    setShowResultsContainer(false);
    props.onItemClick(item);
  };

  return (
    <div css={autoCompleteRoot}>
      <div css={autoCompleteSearchBox}>
        <Input
          Icon={
            props.icon ? (
              props.icon
            ) : (
              <SearchRoundedIcon
                sx={{ color: "#65656B", fontSize: "1.8rem" }}
              />
            )
          }
          placeholder={placeholder}
          id={id}
          name={name}
          onChange={handleInputChange}
          onClick={handleInputClick}
          value={inputValue}
        />
      </div>
      <Stack
        component={"ul"}
        css={autoCompleteSearchResults(showResultsContainer)}
      >
        {/** Not found */}
        {!props.resultsArray && (
          <h3 key={"notfound"}>{TEAM_DETAIL_PAGE.TEAM_NAME_NOT_FOUND}</h3>
        )}

        {props.resultsArray?.length === 0 ? (
          <LoadingSkeleton />
        ) : /* Render select list */
        props.renderItem ? (
          // Render a custom component
          props.resultsArray?.map((item, i) => (
            <>
              <li key={i} onClick={() => handleOnItemClick(item)}>
                {props.renderItem!(item)}
              </li>
            </>
          ))
        ) : (
          // Render Simple list of strings
          props.resultsArray?.map((item, i) => (
            <li key={i} onClick={() => handleOnItemClick(item)}>
              {item as string}
            </li>
          ))
        )}

        {/** Not found */}
        {props.resultsArray?.length !== 0 && (
          <li key={"notfoundComponent"}>{props.renderNotFound}</li>
        )}
      </Stack>
    </div>
  );
};

const LoadingSkeleton = () => {
  return Array.from({ length: 3 }, (_, i) => (
    <Stack key={i}>
      <Skeleton height={35} />
      <Skeleton width={150} height={25} />
    </Stack>
  ));
};

const autoCompleteRoot = css`
  position: relative;
  height: 4rem;
`;

const autoCompleteSearchBox = css`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
`;

const autoCompleteSearchResults = (showResultsContainer: boolean) => css`
  ${transition_all}
  overflow: auto;
  position: absolute;
  top: 4.5rem;
  left: 0;
  z-index: 1;
  width: 100%;
  gap: 0.25rem;
  height: auto;
  padding-inline: 0.5rem;
  padding-block: 0.8rem;
  max-height: 20rem;
  background-color: ${bgColor};
  border-radius: 1rem;

  ${!showResultsContainer &&
  css`
    max-height: 0;
    padding-block: 0;
    visibility: hidden;
    opacity: 0;
    z-index: -10;
  `}

  & > * {
    ${transition_all}
    gap: 0.5rem;
    padding: 0.7rem;
    border-radius: 0.5rem;
    background-color: #7e83a911;
    :hover {
      background-color: #9ca1c411;
    }
    cursor: pointer;
  }
`;
