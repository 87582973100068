import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import capIcon from "../../../assets/mini-league/league-captains-icon.svg";
import card1Image from "../../../assets/landing-page/card-1-img.svg";
import { ExtraFeaturesCardContainer } from "./ExtraFeaturesCardContainer";
import { ExtraFeaturesIconContainer } from "./ExtraFeaturesIconContainer";
import { mq } from "../../../config/styles";

/**
 * A component that renders a card with a heading, subheading, and icon, specific
 * to the first card in the ExtraFeaturesSection of the landing page.
 */
export const ExtraFeaturesCard1 = () => {
  return (
    <ExtraFeaturesCardContainer>
      <Stack css={cardContentStyles}>
        <ExtraFeaturesIconContainer icon={capIcon} />
        <Stack css={titlesStyles}>
          <Typography variant="h1">
            {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD1.TITLE}
          </Typography>
          <Typography variant="h2">
            {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD1.SUBTITLE}
          </Typography>
        </Stack>
        <img src={card1Image} css={imgStyles} />
      </Stack>
    </ExtraFeaturesCardContainer>
  );
};

const cardContentStyles = css`
  height: 100%;
  padding-inline: var(--spacing);
  padding-top: var(--spacing);
  gap: var(--spacing);
`;

const titlesStyles = css`
  flex: 1;
  h1 {
    font-size: clamp(1.5rem, 3vw, 2rem);
    font-weight: 600;
    margin-bottom: 0.8rem;
  }
  h2 {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    font-weight: 400;
    color: #98a2b3;
  }
`;

const imgStyles = css`
  max-height: 20rem;

  ${mq["md"]} {
    max-height: none;
  }
`;
