import { Helmet } from "react-helmet";
import { MINI_LEAGUE_PAGE } from "../../constants/mini-league.constants";
import { SelectPhaseDropDown } from "../../components";
import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { MiniLeagueStandingsTable } from "./components";

/**
 * Renders the mini league standings page
 */

export const MiniLeagueStandingPage = () => {
  return (
    <Stack css={rootStyles}>
      <Helmet>
        <title>{MINI_LEAGUE_PAGE.META.TITLE}</title>
      </Helmet>

      <SelectPhaseDropDown />

      <MiniLeagueStandingsTable />
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;
