import { useState } from "react";
import { css } from "@emotion/react";
import {
  bgColor,
  bgColorLight,
  redColor,
  secondaryBgColor,
  secondaryColor,
} from "../../config/theme";
import { ROUTES } from "../../config/routes";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { Button, ErrorModal } from "..";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import divider from "../../assets/icons/side-bar-divider.svg";
import { useUserContext } from "../../context/UserContext";
import { mq } from "../../config/styles";
import X_SOCIAL_PLATFORM from "../../assets/icons/x.svg";
import reddit from "../../assets/icons/reddit.svg";
import {
  MODAL_ERROR_MESSAGES,
  SIDE_NAVIGATION_BAR_TITLES,
  SOCIAL_MEDIA_LINKS,
} from "../../constants/global.constants";
import { useNavigationItem } from "../../utils/hooks/useNavigationItems";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import { useIsSharedURL } from "../../utils/hooks/useIsSharedURL";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";

const SOCIAL_MEDIA = [
  {
    link: SOCIAL_MEDIA_LINKS.TWITTER,
    title: SIDE_NAVIGATION_BAR_TITLES.SOCIAL_MEDIA_TITLE.TWITTER,
    icon: X_SOCIAL_PLATFORM,
  },
  {
    link: SOCIAL_MEDIA_LINKS.REDDIT,
    title: SIDE_NAVIGATION_BAR_TITLES.SOCIAL_MEDIA_TITLE.REDDIT,
    icon: reddit,
  },
];

interface ISideNavigationMenu {
  close?: () => void;
}

/** Render the side navigation menu  */
export const SideNavigationMenu = (props: ISideNavigationMenu) => {
  // get the current pathname and split to the get the root of the path and add / at the start
  const pathname = `/${useLocation().pathname.split("/")[1]}`;
  const { signOutUser } = useUserContext();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const navigate = useNavigateState();
  const { user } = useUserContext();
  const { isShared } = useIsSharedURL();

  const MAIN_NAVIGATION = useNavigationItem();

  const handleClose = () => {
    if (props.close) props.close();
  };

  const handleSignOutOnclick = () => {
    setIsLogoutModalOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setIsLogoutModalOpen(false);
  };

  const handleRootClick = () => {
    handleClose();
  };

  const handleSidebarClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  const handleLogOutClick = async () => {
    signOutUser();
    setIsLogoutModalOpen(false);
  };

  const handleChangeTeamClick = () => {
    navigate(ROUTES.TEAM_ID_PAGE, {
      state: {
        changeTeamButton: true,
      },
    });
    setIsLogoutModalOpen(false);
  };

  return (
    <Box css={sideBarRootStyle} onClick={handleRootClick}>
      <ErrorModal
        onCloseIconButton={handleCloseModal}
        isOpen={isLogoutModalOpen}
        title={MODAL_ERROR_MESSAGES.LOGOUT.TITLE}
        buttons={
          <Stack gap={2} width={"100%"}>
            <Button button="solid" color={redColor} onClick={handleLogOutClick}>
              {MODAL_ERROR_MESSAGES.LOGOUT.LOGOUT_BUTTON}
            </Button>
            <Button button="outline" onClick={handleChangeTeamClick}>
              {MODAL_ERROR_MESSAGES.LOGOUT.CHANGE_TEAM_BUTTON}
            </Button>
          </Stack>
        }
      />
      <Stack component={"nav"} css={sideBarStyle} onClick={handleSidebarClick}>
        <ul>
          {MAIN_NAVIGATION.map((navItem, index) => (
            <li key={index}>
              <NavLink
                onClick={handleClose}
                to={navItem.path}
                css={[
                  navItemStyle,
                  navItem.isActivePath ? activeLink : undefined,
                ]}
              >
                <navItem.icon
                  css={iconStyle}
                  fill={navItem.isActivePath ? secondaryColor : undefined}
                />
                <Typography variant="h1" css={navItemTitleStyles}>
                  {navItem.title}
                </Typography>
              </NavLink>
            </li>
          ))}
        </ul>

        <ul>
          {SOCIAL_MEDIA.map((navItem, index) => (
            <li key={index}>
              <NavLink
                target="_blank"
                to={navItem.link}
                css={[
                  navItemStyle,
                  pathname === navItem.link ? activeLink : undefined,
                ]}
              >
                <img css={[iconStyle, socialIconStyle]} src={navItem.icon} />
                <Typography variant="h1" css={navItemTitleStyles}>
                  {navItem.title}
                </Typography>
              </NavLink>
            </li>
          ))}
        </ul>

        <img src={divider} alt="divider" css={divideStyle} />
        {user && !user.isAnonymous && (
          <Button css={navItemStyle} onClick={handleSignOutOnclick}>
            <LogoutRoundedIcon css={iconStyle} />
            <Typography variant="h1" css={navItemTitleStyles}>
              {SIDE_NAVIGATION_BAR_TITLES.LOGOUT}
            </Typography>
          </Button>
        )}

        {!user && isShared && (
          <Button
            to={ROUTES.REGISTER_PAGE}
            css={navItemStyle}
            onClick={handleSignOutOnclick}
          >
            <LogoutRoundedIcon css={iconStyle} />
            <Typography variant="h1" css={navItemTitleStyles}>
              {SIDE_NAVIGATION_BAR_TITLES.SIGNUP}
            </Typography>
          </Button>
        )}

        {user && user.isAnonymous && (
          <Button
            onClick={handleChangeTeamClick}
            css={[
              navItemStyle,
              css`
                background-color: transparent;
                height: 3rem;
                border: grey 1px solid;
              `,
            ]}
            button="outline"
          >
            <SwapHorizRoundedIcon css={iconStyle} />
            <Typography variant="h1" css={navItemTitleStyles}>
              {SIDE_NAVIGATION_BAR_TITLES.CHANGE_TEAM}
            </Typography>
          </Button>
        )}
      </Stack>
    </Box>
  );
};

const sideBarRootStyle = css`
  background-color: ${bgColor}77;
  backdrop-filter: blur(3px);
  width: 100vw;
  height: 100%;

  ${mq["md"]} {
    width: auto;
  }
`;

const sideBarStyle = css`
  overflow: auto;
  width: 80%;
  max-width: 22rem;
  height: 100%;
  padding: 1rem;
  background-color: ${secondaryBgColor};
  border-top: 1px solid ${bgColorLight};

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
  }

  ul:nth-of-type(1) {
    flex-grow: 1;
  }

  ${mq["md"]} {
    width: 100%;
  }
`;

const navItemStyle = css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.75rem;
  padding: 1.25rem 1.5rem;
  height: 3.5rem;
  border-radius: 1rem;
  color: #8e8e8e;
  :hover {
    background-color: ${bgColorLight};
  }
`;

const activeLink = css`
  background: ${bgColor};
  color: ${secondaryColor};
`;

const navItemTitleStyles = css`
  font-size: 1rem;
  font-weight: 400;
`;

const iconStyle = css`
  width: 2rem;
`;

const socialIconStyle = css`
  padding: 0.4rem;
`;

const divideStyle = css`
  margin-block: 0.5rem;
`;
