import { atom } from "jotai";
import { IFplAllInfoPhase } from "../types/types";

/** These Atoms contains the all global info states and update functions */

/** Mini league phases Atom */
export const phasesAtom = atom<IFplAllInfoPhase[]>([]);

/** Selected mini league phase atom */
export const selectedPhasesAtom = atom<IFplAllInfoPhase | null>(null);

/** Initiate the selected phase with first element in the phases Atom Array */
export const initializeSelectedPhaseAtom = atom(null, (get, set) => {
  set(selectedPhasesAtom, get(phasesAtom)[0]);
});

/** update the selected phase  */
export const setSelectedPhaseAtom = atom(
  null,
  (get, set, id: number | string) => {
    set(selectedPhasesAtom, get(phasesAtom).find((phase) => phase.id === id)!);
  }
);
