export const FixturesIcon = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00049 7C4.00049 6.46957 4.2112 5.96086 4.58627 5.58579C4.96135 5.21071 5.47006 5 6.00049 5H18.0005C18.5309 5 19.0396 5.21071 19.4147 5.58579C19.7898 5.96086 20.0005 6.46957 20.0005 7V19C20.0005 19.5304 19.7898 20.0391 19.4147 20.4142C19.0396 20.7893 18.5309 21 18.0005 21H6.00049C5.47006 21 4.96135 20.7893 4.58627 20.4142C4.2112 20.0391 4.00049 19.5304 4.00049 19V7Z"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0005 3V7"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99951 3V7"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00049 11H20.0005"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9995 15H11.9995"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15V18"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
