import { Helmet } from "react-helmet";
import { AppLogoDivider, Link } from "../../../components";
import { Typography } from "@mui/material";
import { css } from "@emotion/react";
import { container, divider, mq } from "../../../config/styles";
import { secondaryColor, white } from "../../../config/theme";
import { SignInForm } from "./SignInForm";
import { SIGN_IN_PAGE_CONTENT } from "../../../constants/auth-pages.constants";
import { ROUTES } from "../../../config/routes";
import { FormContainer, OAuthButton } from "../components";
import { useLocation } from "react-router-dom";

/** Renders the sign in page  */
export const SignInPage = () => {
  const location = useLocation();
  return (
    <FormContainer>
      <main css={root}>
        <Helmet>
          <title>{SIGN_IN_PAGE_CONTENT.META.TITLE}</title>
        </Helmet>
        <div css={heading}>
          <Typography variant="h1">
            {SIGN_IN_PAGE_CONTENT.TITLE.PRE_HEADING}
          </Typography>
          <Typography variant="h2">
            {SIGN_IN_PAGE_CONTENT.TITLE.HEADING}
          </Typography>
          <hr />
          <Typography variant="h3">{SIGN_IN_PAGE_CONTENT.SUBTITLE}</Typography>
        </div>

        <OAuthButton provider="google">
          {SIGN_IN_PAGE_CONTENT.GOOGLE_BUTTON_TEXT}
        </OAuthButton>

        <AppLogoDivider />

        <SignInForm />

        <Typography>
          {SIGN_IN_PAGE_CONTENT.HAVE_ACCOUNT.TEXT}{" "}
          <Link to={ROUTES.REGISTER_PAGE} state={location.state}>
            {SIGN_IN_PAGE_CONTENT.HAVE_ACCOUNT.CTA}
          </Link>
        </Typography>
      </main>
    </FormContainer>
  );
};

const root = css`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  ${mq["md"]} {
    margin-top: 0;
  }
`;

const heading = css`
  text-align: center;
  width: 100%;
  h1 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 2.25rem;
    color: ${white};
    font-weight: 700;
  }
  h3 {
    font-size: 2.375rem;
    color: ${secondaryColor};
    margin-bottom: 1rem;
    font-weight: 700;
  }
  hr {
    ${divider}
  }
`;
