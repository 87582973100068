import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useGetFixtures } from "../../utils/hooks/useGetFixtures";
import { PATH_PARAMS } from "../../config/routes";
import { css } from "@emotion/react";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { PageHeader } from "../../components";
import { FIXTURES_RESULTS } from "../../constants/fixtures-pages.constants";
import { Helmet } from "react-helmet";
import { black, darkGray, white } from "../../config/theme";
import { getResultsPageDate } from "../../utils/dateHandlers";
import { primaryGradient } from "../../config/styles";
import { FixtureResultGameScore } from "./components";
import { SyntheticEvent } from "react";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import { useGetUrl } from "../../utils/hooks/useGetUrl";

/** Renders a single fixture results page */
export const FixtureResultLayout = () => {
  const urlParams = useParams();
  const { pathname, search } = useLocation();
  const { getFixtureResultsOverviewPath, getFixtureResultsOwnershipPath } =
    useGetUrl();
  const { fixturesData } = useGetFixtures();
  const navigate = useNavigateState();

  const fixtureData =
    fixturesData &&
    fixturesData.fixtures.find(
      (fixture) => fixture.id === Number(urlParams[PATH_PARAMS.FIXTURES_ID])
    );

  const handleTabClick = (_event: SyntheticEvent, value: string) => {
    navigate(value);
  };

  return (
    <Stack css={rootStyles}>
      <Helmet>
        <title>{FIXTURES_RESULTS.META.TITLE}</title>
      </Helmet>

      <Box css={pageTitleContainer}>
        <PageHeader title={FIXTURES_RESULTS.TITLE} />
      </Box>

      {fixtureData && !fixtureData.started ? (
        <Typography css={[dateStyles]}>
          {getResultsPageDate(fixtureData.kickoff_time)}
        </Typography>
      ) : (
        fixtureData &&
        !fixtureData.started && (
          <Typography css={[dateStyles, loadingStyles]}>loading</Typography>
        )
      )}

      <FixtureResultGameScore fixtureData={fixtureData} />

      <Tabs
        onChange={handleTabClick}
        css={tabsContainerStyles}
        value={pathname + search}
      >
        <Tab
          css={tabStyles}
          LinkComponent={Link}
          label={FIXTURES_RESULTS.TABS.overview.label}
          value={getFixtureResultsOverviewPath(
            urlParams[PATH_PARAMS.FIXTURES_ID]!
          )}
        />
        <Tab
          css={tabStyles}
          LinkComponent={Link}
          label={FIXTURES_RESULTS.TABS.playerOwnership.label}
          value={getFixtureResultsOwnershipPath(
            urlParams[PATH_PARAMS.FIXTURES_ID]!
          )}
        />
      </Tabs>

      <Outlet />
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;

const pageTitleContainer = css`
  width: fit-content;
  margin-inline: auto;
`;

const dateStyles = css`
  background-color: white;
  color: ${black};
  border-radius: 9999px;
  font-weight: 500;
  width: fit-content;
  margin-inline: auto;
  padding: 0rem 0.5rem;
`;

const tabsContainerStyles = css`
  background-color: #222232;

  .MuiTabs-indicator {
    display: none;
  }
`;

const tabStyles = css`
  flex: 1;
  max-width: none;
  font-weight: 600;
  color: ${darkGray};
  text-transform: capitalize;

  &.Mui-disabled {
    color: ${darkGray};
  }

  &.Mui-selected {
    color: ${white};
    position: relative;
    ::after {
      position: absolute;
      content: "";
      ${primaryGradient};
      width: 70%;
      height: 0.2rem;
      bottom: 0;
      left: 50%;
      translate: -50% 0;
      border-radius: 999px;
    }
  }
`;

const loadingStyles = css`
  opacity: 0.3;
`;
