import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import { container, mq } from "../../../config/styles";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { FORGOT_PASSWORD_PAGE } from "../../../constants/auth-pages.constants";
import { Header, FormContainer } from "../components";
import { AppLogoDivider } from "../../../components";

/** Render the forgot password page */
export const ForgotPasswordPage = () => {
  return (
    <FormContainer>
      <main css={root}>
        <Helmet>
          <title>{FORGOT_PASSWORD_PAGE.META.TITLE}</title>
        </Helmet>
        <Header
          title={FORGOT_PASSWORD_PAGE.TITLE}
          subtitle={FORGOT_PASSWORD_PAGE.SUBTITLE}
        />

        <AppLogoDivider />

        <ForgotPasswordForm />
      </main>
    </FormContainer>
  );
};

const root = css`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  ${mq["md"]} {
    margin-top: 0;
  }
`;
