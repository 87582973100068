import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { IFplManager } from "../../../utils/types/types";
import { FPL_CHAMP_ENDPOINTS } from "../../../config/fplChampEndpoints.constants";
import { axiosInstance } from "../../../config/axios.config";
import {
  Accordion,
  AutoCompleteInput,
  Button,
  Input,
  Link,
} from "../../../components";
import { TEAM_DETAIL_PAGE } from "../../../constants/auth-pages.constants";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
  bgColor,
  redColor,
  secondaryColor,
  tableHeaderBgColor,
} from "../../../config/theme";
import userIcon from "../../../assets/icons/user.svg";
import teamIdGuideImage from "../../../assets/auth-pages/team-id-guide.png";
import { mq } from "../../../config/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SESSION_STORAGE } from "../../../config/app.config";

interface ISearchByTeamName {
  inputType: "byName" | "byId";
  handleItemClick(item: IFplManager): void;
  onCantFindTeamClick(): void;
}

/**
 * SearchTeam component renders a search bar that allows the user to search for teams by name or team id.
 * It renders an autocomplete dropdown list of teams that match the search query.
 * When the user selects a team, it calls the `handleItemClick` function and passes the selected team as an argument.
 * If the user can't find their team, they can click on the 'Can't find your team?' button to trigger the `onCantFindTeamClick` function.
 * The component also renders a button that allows the user to navigate to the team details page.
 * The component takes an object with the following properties as its only argument:
 *  - `inputType`: string, either "byName" or "byId", determines the type of search
 *  - `handleItemClick`: function, takes the selected team as an argument
 *  - `onCantFindTeamClick`: function, takes no arguments
 */
export const SearchTeam = (props: ISearchByTeamName) => {
  const [managers, setManagers] = useState<IFplManager[] | null>([]);
  const [searchParam, setSearchParam] = useState("");

  const [selectedTeam, setSelectedTeam] = useState<IFplManager | null>(null);

  const getManagers = async () => {
    const { data: managers } = await axiosInstance.get<IFplManager[]>(
      FPL_CHAMP_ENDPOINTS.GET_TEAM_ID_BY_TEAM_NAME(searchParam)
    );
    if (managers.length === 0) {
      setManagers(null);
    } else {
      setManagers(managers);
    }
  };

  const handleAutoCompleteChange = (inputValue: string) => {
    setSearchParam(inputValue);
    setManagers([]);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchParam) {
        getManagers();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchParam]);

  const handleItemClick = (item: IFplManager) => {
    setSelectedTeam(item);
    props.handleItemClick(item);
  };

  const handleCloseBtn = () => {
    setSelectedTeam(null);
    sessionStorage.removeItem(SESSION_STORAGE.LANDING_PAGE_TEAM_ID);
  };

  return (
    <div>
      {props.inputType === "byName" && !selectedTeam && (
        <AutoCompleteInput<IFplManager>
          name="team_id"
          id="team_id"
          placeholder={TEAM_DETAIL_PAGE.TEAM_MANE_FIELD_PLACEHOLDER}
          onAutoCompleteChange={handleAutoCompleteChange}
          resultsArray={managers}
          onItemClick={handleItemClick}
          renderItem={(item) => (
            <Box css={autoCompleteRenderItemStyle}>
              <Typography>{item.entry_name}</Typography>
              <Typography>{item.player_name}</Typography>
            </Box>
          )}
          renderNotFound={
            <Box>
              <Button
                button="solid"
                color={redColor}
                onClick={props.onCantFindTeamClick}
              >
                Can’t find your team?
              </Button>
            </Box>
          }
        />
      )}

      {props.inputType === "byName" && selectedTeam && (
        <Stack css={selectedTeamStyles}>
          <Typography variant="h1">
            Team Name: {selectedTeam.entry_name}
          </Typography>
          <Typography variant="h2">
            Player Name: {selectedTeam.player_name}
          </Typography>

          <IconButton onClick={handleCloseBtn}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
      )}

      {props.inputType === "byId" && (
        <>
          <Input
            Icon={<img src={userIcon} />}
            placeholder={TEAM_DETAIL_PAGE.TEAM_ID_FIELD_PLACEHOLDER}
            id="team_id"
            name="team_id"
          />
          <Accordion title={TEAM_DETAIL_PAGE.FIND_TEAM_ID.TITLE}>
            <div css={steps}>
              <ol>
                <li>
                  {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.TEXT}{" "}
                  <Link
                    css={linkStyle}
                    to={TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.LINK}
                  >
                    {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.LINK_TEXT}
                  </Link>
                </li>

                <li>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.TWO}</li>
                <li>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.THREE}</li>

                <li>
                  {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.FIRST_PART}{" "}
                  <b>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.ONLY}</b>{" "}
                  {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.LAST_PART}
                </li>
              </ol>

              <img src={teamIdGuideImage} alt="Team Id Guide" />
            </div>
          </Accordion>
        </>
      )}
    </div>
  );
};

const autoCompleteRenderItemStyle = css`
  & > * {
    :first-of-type {
      font-weight: 700;
      font-size: 1.2rem;
    }
    :last-of-type {
      color: ${secondaryColor};
      font-weight: 700;
      font-size: 0.75rem;
    }
  }
`;

const selectedTeamStyles = css`
  position: relative;
  background-color: ${bgColor};
  border-radius: 0.875rem;
  padding: 0.75rem 1rem;
  gap: 0.5rem;

  align-items: start;

  h1 {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    font-weight: 600;
  }

  h2 {
    font-size: clamp(0.75rem, 2vw, 1rem);
    font-weight: 400;
    /* color: ${secondaryColor}; */
  }

  button {
    padding: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    aspect-ratio: 1/1;
    background-color: ${tableHeaderBgColor};

    svg {
      color: white;
      width: 0.875rem;
    }

    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }
`;

const steps = css`
  h1 {
    font-size: 1.25rem;
  }

  ol {
    margin: 0;
    padding: 0 1rem;
    font-size: 0.875rem;
    li {
      list-style: decimal;
      padding: 0 0 0.1rem 0.5rem;
    }
  }

  img {
    margin-top: 1rem;
    width: 100%;
  }

  ${mq["md"]} {
    h1 {
      font-size: 1.75rem;
    }
    ol {
      font-size: 1.125rem;
    }
  }
`;

const linkStyle = css`
  color: white;
  text-decoration: underline;
`;
