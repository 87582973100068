import { Typography } from "@mui/material";
import { css } from "@emotion/react";
import { secondaryColor } from "../../../config/theme";

interface IHeader {
  title: string;
  subtitle?: string;
}

/** Auth pages header */
export const Header = ({ title, subtitle }: IHeader) => {
  return (
    <div css={heading}>
      <Typography variant="h1">{title}</Typography>
      {subtitle && <Typography>{subtitle}</Typography>}
    </div>
  );
};

const heading = css`
  text-align: center;
  h1 {
    font-size: 2.375rem;
    color: ${secondaryColor};
    margin-bottom: 1rem;
  }
`;
