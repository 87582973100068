import { css } from "@emotion/react";
import {
  PageHeader,
  Skeleton,
  FixtureCard,
  FixtureCardSkeleton,
  AppJoyride,
} from "../../components";
import { Stack, Typography } from "@mui/material";
import { FIXTURE_PAGE } from "../../constants/fixtures-pages.constants";
import { Helmet } from "react-helmet";
import { useGetFixtures } from "../../utils/hooks/useGetFixtures";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { secondaryColor } from "../../config/theme";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import { getDeadLineDate } from "../../utils/dateHandlers";
import { SelectFutureGameWeekDropDown } from "../../components/SelectFutureGameWeekDropDown";
import { useLocation } from "react-router-dom";
import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: ".first-step",
    disableBeacon: true,
    content: "",
  },
];

/** Renders the fixtures page */
export const FixturesPage = () => {
  const location = useLocation();
  const { fixturesData } = useGetFixtures();
  const { getFixtureResultsOverviewPath } = useGetUrl();
  const navigate = useNavigateState();

  const handleFixtureCardClick = (fixtureId: number) => {
    navigate(getFixtureResultsOverviewPath(fixtureId));
  };

  return (
    <Stack css={rootStyles}>
      <Helmet>
        <title>{FIXTURE_PAGE.META.TITLE}</title>
      </Helmet>

      <AppJoyride
        run={location.state && location.state.comingFromCoolFeatureBTN}
        steps={steps}
        title="Fixture importance"
        subTitle="You can find out how important this fixture is from here."
      />

      <PageHeader title={FIXTURE_PAGE.TITLE} />

      <SelectFutureGameWeekDropDown />

      <Typography css={deadlineStyles}>
        <NotificationsNoneIcon />
        {fixturesData ? (
          `${FIXTURE_PAGE.FIXTURE_DEADLINE_TITLE} ${getDeadLineDate(
            fixturesData.deadline_time
          )}`
        ) : (
          <Skeleton />
        )}
      </Typography>

      <Stack css={fixturesCardsContainer}>
        {fixturesData
          ? fixturesData.fixtures.map((fixture, i) => (
              <FixtureCard
                joyRideClassName={i === 0 ? "first-step" : ""}
                key={fixture.id}
                {...fixture}
                onFixtureCardClick={() => handleFixtureCardClick(fixture.id)}
              />
            ))
          : Array.from({ length: 5 }, (_, i) => (
              <FixtureCardSkeleton key={i} />
            ))}
      </Stack>
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;

const deadlineStyles = css`
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  svg {
    color: ${secondaryColor};
  }
`;

const fixturesCardsContainer = css`
  gap: 1rem;
`;
