import { css } from "@emotion/react";
import { secondaryColor } from "../../config/theme";
import { Stack, Typography } from "@mui/material";
import { TERMS_OF_SERVICE_PAGE_CONTENT } from "../../constants/privacyAndTerms.constants";
import { mq } from "../../config/styles";
import { Helmet } from "react-helmet";

/** Renders the terms of services pages */
export const TermsOfServicePage = () => {
  return (
    <Stack css={root}>
      <Helmet>
        <title>{TERMS_OF_SERVICE_PAGE_CONTENT.META.TITLE}</title>
      </Helmet>
      <Typography variant="h1" css={titleStyle}>
        {TERMS_OF_SERVICE_PAGE_CONTENT.TITLE}
      </Typography>

      {TERMS_OF_SERVICE_PAGE_CONTENT.CONTENT.map((item, index) => (
        <section key={`${index + 1}. ${item.TITLE}`}>
          <Typography css={sectionTitleStyle}>{`${index + 1}. ${
            item.TITLE
          }`}</Typography>
          <Typography>
            {item.PARAGRAPH ? (
              <Typography css={textStyle}>{item.PARAGRAPH}</Typography>
            ) : (
              <ul css={unorderedListStyle}>
                {item.CONTENT_LIST?.map((listItem) => (
                  <li css={textStyle}>{listItem}</li>
                ))}
              </ul>
            )}
          </Typography>
        </section>
      ))}
    </Stack>
  );
};

const root = css`
  color: #dddedf;
  gap: 1rem;
  ${mq["md"]} {
    gap: 1.5rem;
  }
`;

const titleStyle = css`
  color: ${secondaryColor};
  font-weight: 600;
  font-size: 1.25rem;
  ${mq["md"]} {
    font-size: 1.5rem;
  }
`;

const sectionTitleStyle = css`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  ${mq["md"]} {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
`;

const unorderedListStyle = css`
  margin: 0 0 1rem 1rem;
  li {
    list-style-type: disc;
  }
`;

const textStyle = css`
  font-size: 0.75rem;
  ${mq["md"]} {
    font-size: 1rem;
  }
`;
