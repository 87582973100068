import { Stack } from "@mui/material";
import { secondaryBgColor } from "../../../config/theme";
import { SerializedStyles, css } from "@emotion/react";
import { mq } from "../../../config/styles";
import { Skeleton } from "../../../components";

interface IHighlightsCardSkeleton {
  extraStyles?: SerializedStyles;
}

/** Renders the loading skeleton for the Highlights cards */
export const HighlightsCardSkeleton = (props: IHighlightsCardSkeleton) => {
  return (
    <Stack css={[skeletonStyle, props.extraStyles]} direction={"row"} gap={2}>
      <Stack css={textSkeleton}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
      <Stack css={circularSkeletonContainer}>
        <Skeleton css={circularSkeleton} />
      </Stack>
    </Stack>
  );
};

const skeletonStyle = css`
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${secondaryBgColor}55;
  height: 12rem;
  gap: 2rem;

  .MuiSkeleton-root {
    background-color: ${secondaryBgColor};
  }

  & > * {
    flex: 1;
  }

  ${mq["sm"]} {
    height: 13rem;
  }

  ${mq["md"]} {
    height: 17.5rem;
    gap: 1.5rem;
    padding: 1.5rem 3.7rem;
  }
`;

const textSkeleton = css`
  justify-content: center;
  gap: 1rem;

  .MuiSkeleton-root {
    flex: 1;
  }

  .MuiSkeleton-root:nth-of-type(2) {
    flex: 2;
  }
`;

const circularSkeleton = css`
  aspect-ratio: 1/1;
  border-radius: 999px;
`;

const circularSkeletonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
