import * as yup from "yup";
import { AUTH_ERROR_MESSAGES } from "../../../constants/auth-pages.constants";

const email = yup
  .string()
  .email(AUTH_ERROR_MESSAGES.EMAIL.INVALID_FORMAT)
  .required(AUTH_ERROR_MESSAGES.EMAIL.REQUIRED);

export const subscribeInputYupSchema = yup.object().shape({
  email,
});

export type subscribeInputType = yup.InferType<typeof subscribeInputYupSchema>;
