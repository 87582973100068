import { useEffect, useState } from "react";

/**
 * timer hook that counter down from an initial input
 * The timer object containing current minutes, seconds, and a function to reset the timer.
 * to reset use the reset function
 * @returns { minutes, seconds, resetTimer }
 */

export const useTimer = ({
  initialMinutes,
  initialSeconds,
}: {
  initialMinutes: number;
  initialSeconds: number;
}) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const resetTimer = () => {
    setMinutes(initialMinutes);
    setSeconds(initialSeconds);
  };

  return { minutes, seconds, resetTimer } as const;
};
