import { bgColor, bgColorDark } from "../../../config/theme";
import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { cardBorderRadius, homeCardBgColor } from "../../../config/styles";
import { TRANSFERS_PAGE_CONTENT } from "../../../constants/transfers.constants";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS, QUERY_PARAMS } from "../../../config/routes";
import { TransferRow, TransferRowSkeleton } from "./TransferRow";
import { IGetLeagueTransfersRes } from "../../../utils/hooks/useGetLeagueTransfers";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import {
  TransfersHeaderSection,
  TransfersHeaderSectionSkeleton,
} from "../../../pages/transfers-pages/components";
import { Button, Skeleton } from "../../../components";

interface ITransfersCard {
  leagueTransfers: IGetLeagueTransfersRes["leagueTransfers"][0];
}

/**
 * A component that displays a league team's transfers for a selected game week.
 * The component displays the team name, net points, and active chip for the
 * selected game week. If there are transfers for the selected game week, the
 * component displays the transfers in a table. If there are no transfers for
 * the selected game week, the component displays a message indicating that
 * there are no transfers. The component also displays a button that links to the
 * home page.
 */
export const TransfersCard = (props: ITransfersCard) => {
  const [searchParams] = useSearchParams();
  const { getManagerTransfersPath, getTeamViewPath } = useGetUrl();

  // if there is no transfers for this game week
  const transferInfo = props.leagueTransfers.transfers[
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!
  ] as
    | IGetLeagueTransfersRes["leagueTransfers"][0]["transfers"][" "]
    | undefined;

  const navigateTo = (
    teamId: number,
    eplPlayerId: number,
    gameWeek: number
  ) => ({
    pathname: getTeamViewPath(teamId).split("?")[0],
    search: createSearchParams({
      [PATH_PARAMS.SELECTED_GAME_WEEK]: String(gameWeek),
      [QUERY_PARAMS.EPL_PLAYER_INFO_MODAL]: String(eplPlayerId),
    }).toString(),
  });

  return (
    <Stack css={transfersSummaryStyles}>
      <Stack css={topSectionContainerStyles}>
        <TransfersHeaderSection
          activeChip={transferInfo?.active_chip}
          netPoints={transferInfo?.net_points}
          title={props.leagueTransfers.fpl_team_name}
          transfersCost={transferInfo?.event_transfers_cost}
          teamId={props.leagueTransfers.entry}
        />
      </Stack>

      {transferInfo && transferInfo.event_transfers.length !== 0 ? (
        <Stack css={transfersContainerStyles}>
          {transferInfo.event_transfers.map((item, i) => (
            <TransferRow
              key={i}
              elementOutTo={navigateTo(
                item.entry,
                item.element_out,
                transferInfo.event - 1 // previous game week
              )}
              elementInTo={navigateTo(
                item.entry,
                item.element_in,
                transferInfo.event
              )}
              element_in_info={item.element_in_info}
              element_out_info={item.element_out_info}
            />
          ))}
        </Stack>
      ) : (
        <p css={noTransfersStyles}>
          {TRANSFERS_PAGE_CONTENT.TRANSFERS_CARD.NO_TRANSFERS}
        </p>
      )}

      <div css={buttonContainerStyles}>
        <Button
          button="gradient"
          to={getManagerTransfersPath(props.leagueTransfers.entry)}
        >
          {TRANSFERS_PAGE_CONTENT.TRANSFERS_CARD.BTN}
        </Button>
      </div>
    </Stack>
  );
};

export const TransfersCardSkeleton = ({ length = 3 }) => {
  return (
    <Stack
      css={[
        transfersSummaryStyles,
        css`
          background-color: ${bgColorDark};
        `,
      ]}
    >
      <Stack css={topSectionContainerStyles}>
        <TransfersHeaderSectionSkeleton />
      </Stack>

      <Stack
        css={[
          transfersContainerStyles,
          css`
            max-height: none;
          `,
        ]}
      >
        {Array.from({ length: length }, (_, i) => (
          <TransferRowSkeleton key={i} />
        ))}
      </Stack>

      <div css={buttonContainerStyles}>
        <Skeleton
          css={css`
            height: 2.5rem;
            border-radius: 1rem;
            background-color: ${bgColor};
          `}
        />
      </div>
    </Stack>
  );
};

const topSectionContainerStyles = css`
  padding: 1rem;
`;

const transfersSummaryStyles = css`
  ${homeCardBgColor}
  ${cardBorderRadius}

  & > *:not(:last-child) {
    border-bottom: 1px solid #78787831;
  }
`;

const transfersContainerStyles = css`
  max-height: 300px;
  overflow: auto;

  & > * {
    border-bottom: 1px solid #78787831;
  }
`;

const buttonContainerStyles = css`
  padding: 1rem;
`;

const noTransfersStyles = css`
  font-weight: 700;
  text-align: center;
  font-size: clamp(1rem, 2vw, 1.5rem);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;
