import { useParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useQuery } from "react-query";

export interface IMiniLeagueNewEntriesRes {
  hasNext: boolean;
  newEntries: {
    fplManagerName: string;
    fplTeamName: string;
    fplTeamId: number;
  }[];
}

/** useQuery hook that fetch the new league entries  */
export const useGetMiniLeagueNewEntriesTable = ({
  pageNumber,
}: {
  pageNumber: number;
}) => {
  const params = useParams();

  const queryKey = ["new entries", params[PATH_PARAMS.LEAGUE_ID], pageNumber];

  const queryFunction = async () => {
    if (params[PATH_PARAMS.LEAGUE_ID]) {
      const { data } = await axiosInstance.get<IMiniLeagueNewEntriesRes>(
        FPL_CHAMP_ENDPOINTS.MINI_LEAGUE_NEW_ENTRIES(
          params[PATH_PARAMS.LEAGUE_ID]!,
          pageNumber
        )
      );

      return data;
    }
  };

  const { isLoading, data: newEntries } = useQuery({
    queryKey: queryKey,
    queryFn: queryFunction,
  });

  return {
    newEntries,
    isLoading,
  } as const;
};
