import { css } from "@emotion/react";
import {
  AppPagination,
  AppShareButton,
  PageHeader,
  SelectGameWeekDropDown,
} from "../../components";
import { Helmet } from "react-helmet";
import { TRANSFERS_PAGE_CONTENT } from "../../constants/transfers.constants";
import { Box, Stack } from "@mui/material";
import { useIsSharedURL } from "../../utils/hooks/useIsSharedURL";
import { useGetLeagueTransfers } from "../../utils/hooks/useGetLeagueTransfers";
import { TransfersCard, TransfersCardSkeleton } from "./components";
import { useState } from "react";

/**
 * Page to display league transfers.
 * If the URL is shared, only show the transfers and hide the share button.
 * If the URL is not shared, show the share button.
 * If the league transfers are not available, display 3 TransferCardSkeletons.
 * Otherwise, display a TransfersCard component for each league transfer.
 */
export const TransfersPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { isShared } = useIsSharedURL();
  const { leagueTransfers } = useGetLeagueTransfers(pageNumber);

  const handleBackClick = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNextClick = () => {
    if (leagueTransfers && !leagueTransfers.has_next) return;
    setPageNumber(pageNumber + 1);
  };

  return (
    <Stack css={root}>
      <Helmet>
        <title>{TRANSFERS_PAGE_CONTENT.META.TITLE}</title>
      </Helmet>
      <PageHeader title={TRANSFERS_PAGE_CONTENT.TITLE} />

      <Stack css={gwAndShareContainer}>
        <SelectGameWeekDropDown />
        {!isShared && <AppShareButton message={`Check out these transfers`} />}
      </Stack>

      {leagueTransfers
        ? leagueTransfers.leagueTransfers.map((t) => (
            <TransfersCard key={t.entry} leagueTransfers={t} />
          ))
        : Array.from({ length: 3 }, (_, i) => (
            <TransfersCardSkeleton key={i} />
          ))}

      <Box>
        <AppPagination
          backBtnDisabled={pageNumber === 1}
          nextBtnDisabled={!leagueTransfers?.has_next}
          onBackClick={handleBackClick}
          onNextClick={handleNextClick}
          pageNumber={pageNumber}
        />
      </Box>
    </Stack>
  );
};

const root = css`
  gap: 1rem;
`;

const gwAndShareContainer = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
