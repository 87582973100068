import { Typography } from "@mui/material";
import { css } from "@emotion/react";
import { Stack } from "@mui/system";

interface IHighlightsImportantKeyCellProps {
  title: string;
  accentColor: string;
}

/** Renders the important keys in the single highlights cards */
export const HighlightsImportantKeyCell = (
  props: IHighlightsImportantKeyCellProps
) => {
  return (
    <Stack css={importantKeyCellStyle(props.accentColor)}>
      <Typography>{props.title}</Typography>
    </Stack>
  );
};

const importantKeyCellStyle = (accentColor: string) => css`
  height: 100%;
  justify-content: center;
  align-items: center;

  p {
    font-size: inherit;
    color: white;
    color: ${accentColor};
  }
`;
