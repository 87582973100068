import { TEAM_DETAIL_PAGE } from "../../../constants/auth-pages.constants";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { mq } from "../../../config/styles";
import teamIdGuideImage from "../../../assets/auth-pages/team-id-guide.png";
import { Accordion } from "../../../components";
import teamNameGuideImage from "../../../assets/auth-pages/team-name-guide.png";

/**
 * Renders two accordions for these detail page
 * these guides the user to get the team or team id from FPL
 */

export const TeamDetailsAccordion = () => {
  return (
    <div>
      <Accordion title={TEAM_DETAIL_PAGE.FIND_TEAM_NAME.TITLE}>
        <div css={steps}>
          <ol>
            <li>
              {TEAM_DETAIL_PAGE.FIND_TEAM_NAME.GO_TO_FPL.TEXT}{" "}
              <Link
                css={linkStyle}
                to={TEAM_DETAIL_PAGE.FIND_TEAM_NAME.GO_TO_FPL.LINK}
              >
                {TEAM_DETAIL_PAGE.FIND_TEAM_NAME.GO_TO_FPL.LINK_TEXT}
              </Link>
            </li>
            {TEAM_DETAIL_PAGE.FIND_TEAM_NAME.STEPS.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
          <img src={teamNameGuideImage} alt="Team Name Guide" />
        </div>
      </Accordion>

      <Accordion title={TEAM_DETAIL_PAGE.FIND_TEAM_ID.TITLE}>
        <div css={steps}>
          <ol>
            <li>
              {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.TEXT}{" "}
              <Link
                css={linkStyle}
                to={TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.LINK}
              >
                {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.LINK_TEXT}
              </Link>
            </li>

            <li>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.TWO}</li>
            <li>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.THREE}</li>

            <li>
              {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.FIRST_PART}{" "}
              <b>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.ONLY}</b>{" "}
              {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.LAST_PART}
            </li>
          </ol>

          <img src={teamIdGuideImage} alt="Team Id Guide" />
        </div>
      </Accordion>
    </div>
  );
};

const steps = css`
  h1 {
    font-size: 1.25rem;
  }

  ol {
    margin: 0;
    padding: 0 1rem;
    font-size: 0.875rem;
    li {
      list-style: decimal;
      padding: 0 0 0.1rem 0.5rem;
    }
  }

  img {
    margin-top: 1rem;
    width: 100%;
  }

  ${mq["md"]} {
    h1 {
      font-size: 1.75rem;
    }
    ol {
      font-size: 1.125rem;
    }
  }
`;

const linkStyle = css`
  color: white;
  text-decoration: underline;
`;
