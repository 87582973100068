import { css } from "@emotion/react";
import logo from "../../assets/identity/logo_name.svg";
import { Box, IconButton } from "@mui/material";
import { useUserContext } from "../../context/UserContext";
import { navbarHeight, white } from "../../config/theme";
import { container, mq, transition_all } from "../../config/styles";
import { SideNavigationMenu } from "./SideNavigationMenu";
import { useState } from "react";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import { useIsSharedURL } from "../../utils/hooks/useIsSharedURL";
import { ROUTES } from "../../config/routes";
import { Button } from "..";

/** Renders the top navigation bar in the app  */
export const TopNavigationBar: React.FunctionComponent = () => {
  const navigate = useNavigateState();
  const { isShared } = useIsSharedURL();
  const { user } = useUserContext();
  const { getHomePath } = useGetUrl();
  const [isSideNavigationMenuOpen, setIsSideNavigationMenuOpen] =
    useState(false);

  return (
    <>
      <nav css={appBarStyle}>
        {user || isShared ? (
          <IconButton
            css={[burgerMenuStyle(isSideNavigationMenuOpen)]}
            onClick={() => setIsSideNavigationMenuOpen((prev) => !prev)}
          >
            <div></div>
            <div></div>
            <div></div>
          </IconButton>
        ) : null}

        <img
          css={logoStyle}
          src={logo}
          alt="Logo Image"
          onClick={() => navigate(getHomePath())}
        />

        {user && user.isAnonymous && (
          <Box css={loginBTNsContainerStyles}>
            <Button
              button="gradient"
              css={getStartedButton}
              to={ROUTES.REGISTER_PAGE}
            >
              {"Sign Up"}
            </Button>
            <Button
              button="text"
              css={loginButtonStyles}
              to={ROUTES.SIGN_IN_PAGE}
            >
              Login
            </Button>
          </Box>
        )}
      </nav>

      <div css={sidebarStyle(isSideNavigationMenuOpen)}>
        <SideNavigationMenu close={() => setIsSideNavigationMenuOpen(false)} />
      </div>
    </>
  );
};

const sidebarStyle = (isSideNavigationMenuOpen: boolean) => css`
  ${transition_all}
  height: calc(100vh - ${navbarHeight});
  position: absolute;
  z-index: 1000;
  top: ${navbarHeight};
  left: 0;
  transform: translateX(-100%);

  ${isSideNavigationMenuOpen &&
  css`
    transform: translateX(0);
  `}

  ${mq["sm"]} {
    min-width: 50%;
  }
`;

// Define Emotion styles
const appBarStyle = css`
  ${container}
  padding-block: 1rem !important;
  height: ${navbarHeight};
  background-color: #2c2d3f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;

  div {
    display: flex;
    gap: 1rem;
  }

  ${mq["md"]} {
    justify-content: start;
  }
`;

const logoStyle = css`
  width: 11.0625rem;
  object-fit: cover;
  cursor: pointer;

  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;

  ${mq["md"]} {
    position: static;
    translate: 0 0;
    width: 13.0625rem;
  }
`;

const burgerMenuStyle = (isSideNavigationMenuOpen: boolean | undefined) => css`
  ${transition_all}
  --width: 1.40125rem;
  --height: 2rem;

  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translate(0, -50%);
  height: var(--height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  div {
    transform-origin: left;
    ${transition_all}
    background-color: ${white};
    width: var(--width);
    height: 0.17rem;
    border-radius: 9999px;
  }

  ${isSideNavigationMenuOpen &&
  css`
    div {
      /* width: calc(sqrt(pow(var(--height), 2) + pow(var(--width), 2))); */
      /* width: 5rem; */
      /* width: calc(var(--height) * 1.414); */
      width: 1.25rem;
    }
    div:nth-of-type(1) {
      transform: rotate(45deg);
    }
    div:nth-of-type(3) {
      transform: rotate(-45deg);
    }
    div:nth-of-type(2) {
      opacity: 0;
    }
  `}

  ${mq["md"]} {
    display: none;
  }
`;

const loginBTNsContainerStyles = css`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  ${mq["md"]} {
  }
`;

const getStartedButton = css`
  width: fit-content;
  padding: 0 0.5rem;

  ${mq["md"]} {
    padding: 0.5rem 1.5rem;
  }
`;

const loginButtonStyles = css`
  display: none;

  ${mq["md"]} {
    display: flex;
    max-width: fit-content;
    color: #ffffff58;
    padding-inline: 0.2rem !important;
    height: 2.8rem;
  }
`;
