import { Outlet } from "react-router-dom";
import { TopNavigationBar } from "../../components";
import { css } from "@emotion/react";
import { mq } from "../../config/styles";

export const AuthLayout = () => {
  return (
    <main css={mainLayout}>
      <div css={navBar}>
        <TopNavigationBar />
      </div>
      <div css={root}>
        <Outlet />
      </div>
    </main>
  );
};

const mainLayout = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const navBar = css`
  display: none;
  ${mq["md"]} {
    display: block;
  }
`;

const root = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
