import { Stack } from "@mui/material";
import { SerializedStyles, css } from "@emotion/react";
import noise from "../assets/highlights/noise.png";
import noiseLg from "../assets/highlights/noise-lg.png";
import { mq } from "../config/styles";
import { bgColorDark } from "../config/theme";

interface IAppCard {
  accentColor?: string;
  cardStyles?: SerializedStyles;
  handleOnClick?(): void;
  children: React.ReactNode;
}

/**
 * App Card Container that can be clickable
 * Used to wrap all App cards for consistent dimensions
 */

export const AppCard = (props: IAppCard) => {
  return (
    <Stack
      onClick={props.handleOnClick}
      css={[
        AppCardStyle(props.accentColor, Boolean(props.handleOnClick)),
        props.cardStyles,
      ]}
    >
      <picture css={noiseImageContainerStyle}>
        <source srcSet={noiseLg} media="(min-width: 500px)" />
        <img css={noiseImageStyle} src={noise} alt="noise background" />
      </picture>

      {props.children}
    </Stack>
  );
};

/**Loading skeleton for AppCard */
export const AppCardSkeleton = (props: { children: React.ReactNode }) => {
  return (
    <Stack css={[AppCardStyle(), AppCardSkeletonStyle]}>{props.children}</Stack>
  );
};

const AppCardStyle = (
  accentColor?: string,
  isClickable: boolean = false
) => css`
  --accentColor: ${accentColor};
  position: relative;
  border-radius: 1rem;
  padding: 1rem 2rem 0 2rem;
  min-height: 12rem;
  position: relative;
  overflow: hidden;

  ${mq["sm"]} {
    height: 13rem;
  }

  ${mq["md"]} {
    height: 16rem;
    gap: 1.5rem;
    padding: 1.5rem 2rem 0 2rem;
  }

  ${isClickable &&
  css`
    cursor: pointer;
  `}
`;

const noiseImageContainerStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const noiseImageStyle = css`
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: linear-gradient(to right top, #273441, #80cb4f);
  background: linear-gradient(to right top, #273441, var(--accentColor));
`;

const AppCardSkeletonStyle = () => css`
  background-color: ${bgColorDark};
`;
