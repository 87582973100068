import { FormContainer, Header } from "../auth-pages/components";
import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import { CONTACT_US_PAGE_CONTENT } from "../../constants/contact-us-page.constants";
import { container, mq } from "../../config/styles";
import { ContactUsForm } from "./components";
import megaPhone from "../../assets/coming-soon-page/mega-phone.svg";
import { AppLogoDivider } from "../../components";

/**
 * The ContactUsPages component is the main component for the contact-us page.
 *
 * It renders a simple form container with a main section that contains a
 * header with a title and subtitle, a divider, and a contact us form.
 *
 * @returns {JSX.Element} The contact us page component.
 */
export const ContactUsPages = () => {
  return (
    <FormContainer>
      <main css={root}>
        <Helmet>
          <title>{CONTACT_US_PAGE_CONTENT.META.TITLE}</title>
        </Helmet>

        <img src={megaPhone} css={megaPhoneStyles} />

        <Header
          title={CONTACT_US_PAGE_CONTENT.TITLE}
          subtitle={CONTACT_US_PAGE_CONTENT.SUBTITLE}
        />

        <AppLogoDivider />

        <ContactUsForm />
      </main>
    </FormContainer>
  );
};
const root = css`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 3rem;
  ${mq["md"]} {
    padding-top: 0;
  }
`;

const megaPhoneStyles = css`
  width: 30%;
  max-width: 10rem;
`;
