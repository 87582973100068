import { GridColDef } from "@mui/x-data-grid";
import { IMiniLeagueNewEntriesRes } from "../hooks/useGetMiniLeagueNewEntriesTable";

export const newEntriesColumns: GridColDef<
  IMiniLeagueNewEntriesRes["newEntries"][0]
>[] = [
  {
    field: "fplManagerName",
    headerName: "Manger name",
    flex: 1,
    sortable: false,
  },
  {
    field: "fplTeamName",
    headerName: "Team name",
    flex: 1,
    sortable: false,
  },
];
