export const HomeIcon = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.717 15.2913C14.921 15.2913 15.901 16.2643 15.901 17.4603V20.5363C15.901 20.7933 16.107 20.9993 16.371 21.0053H18.277C19.779 21.0053 21 19.7993 21 18.3173V9.59325C20.993 9.08325 20.75 8.60325 20.333 8.28425L13.74 3.02625C12.855 2.32525 11.617 2.32525 10.729 3.02825L4.181 8.28225C3.748 8.61125 3.505 9.09125 3.5 9.61025V18.3173C3.5 19.7993 4.721 21.0053 6.223 21.0053H8.147C8.418 21.0053 8.638 20.7903 8.638 20.5263C8.638 20.4683 8.645 20.4103 8.657 20.3553V17.4603C8.657 16.2713 9.631 15.2993 10.826 15.2913H13.717ZM18.277 22.5053H16.353C15.251 22.4793 14.401 21.6143 14.401 20.5363V17.4603C14.401 17.0913 14.094 16.7913 13.717 16.7913H10.831C10.462 16.7933 10.157 17.0943 10.157 17.4603V20.5263C10.157 20.6013 10.147 20.6733 10.126 20.7413C10.018 21.7313 9.172 22.5053 8.147 22.5053H6.223C3.894 22.5053 2 20.6263 2 18.3173V9.60325C2.01 8.60925 2.468 7.69925 3.259 7.10025L9.794 1.85525C11.233 0.71525 13.238 0.71525 14.674 1.85325L21.256 7.10325C22.029 7.69225 22.487 8.60025 22.5 9.58225V18.3173C22.5 20.6263 20.606 22.5053 18.277 22.5053Z"
        fill={fill}
      />
    </svg>
  );
};
