import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { Skeleton } from "./Skeleton";

interface IPageHeader {
  title: string | null | undefined;
  isLoading?: boolean;
}

/**
 * The header for app page
 */

export const PageHeader = (props: IPageHeader) => {
  return (
    <Typography css={pageTitleStyle} variant="h1">
      {!props.isLoading ? props.title : <Skeleton width={"20rem"} />}
    </Typography>
  );
};

const pageTitleStyle = css`
  font-size: clamp(1.5rem, 3vw, 2.8rem);
  font-weight: 900;
`;
