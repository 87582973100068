export const MyTeamIcon = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
        stroke={fill}
        strokeWidth="1.06667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 3V6H9V3H15Z"
        stroke={fill}
        strokeWidth="1.06667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 18V21H9V18H15Z"
        stroke={fill}
        strokeWidth="1.06667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0005 3C17.5309 3 18.0396 3.21071 18.4147 3.58579C18.7898 3.96086 19.0005 4.46957 19.0005 5V19C19.0005 19.5304 18.7898 20.0391 18.4147 20.4142C18.0396 20.7893 17.5309 21 17.0005 21H7.00049C6.47006 21 5.96135 20.7893 5.58628 20.4142C5.2112 20.0391 5.00049 19.5304 5.00049 19V5C5.00049 4.46957 5.2112 3.96086 5.58628 3.58579C5.96135 3.21071 6.47006 3 7.00049 3H17.0005Z"
        stroke={fill}
        strokeWidth="1.06667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0005 12H5.00049"
        stroke={fill}
        strokeWidth="1.06667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
