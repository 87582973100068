import Rollbar from "rollbar";
import { ENV_OPTIONS } from "./app.config";

export const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  captureUncaught: import.meta.env.VITE_ENVIRONMENT !== ENV_OPTIONS.LOCAL,
  captureUnhandledRejections:
    import.meta.env.VITE_ENVIRONMENT !== ENV_OPTIONS.LOCAL,
};

export const rollbar = new Rollbar({
  ...rollbarConfig,
  addErrorContext: true,
  version: "1.0.0",
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: "1.0.0",
      },
    },
  },
});

export const ROLLBAR_USER = "rollBarUser";
