import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { MINI_LEAGUE_SUMMARY } from "../../../constants/home-page.constants";
import {
  cardBorderRadius,
  homeCardBgGradient,
  title,
} from "../../../config/styles";
import { PATH_PARAMS } from "../../../config/routes";
import waring from "../../../assets/icons/warning.svg";

/**
 * A waring component for the mini league summary in the home page
 * Displayed when the selected game week is not live gam week
 */
export const MiniLeagueSummaryErrorCard = () => {
  const [searchParams] = useSearchParams();

  return (
    <Stack css={errorCardRootStyles}>
      <Typography css={title}>
        {MINI_LEAGUE_SUMMARY.ERROR_CARD.TITLE}
        {searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)}
      </Typography>

      <img css={errorCardImgStyles} src={waring} alt="warning" />
      <Typography css={errorCardMsgStyles}>
        {MINI_LEAGUE_SUMMARY.ERROR_CARD.ERR_MESSAGE}
      </Typography>
    </Stack>
  );
};

const errorCardRootStyles = css`
  ${homeCardBgGradient}
  ${cardBorderRadius}
  padding: 1rem;
  gap: 1rem;
`;

const errorCardImgStyles = css`
  width: 50%;
  margin-inline: auto;
  max-width: 15rem;
`;

const errorCardMsgStyles = css`
  text-align: center;
`;
