import { useQuery } from "react-query";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS, QUERY_PARAMS } from "../../config/routes";
import { IFplManager } from "../types/types";
import { useUserContext } from "../../context/UserContext";

export interface IPlayerOwnershipFixture {
  team_a_name: string;
  team_h_name: string;
  team_a_player: teamPlayer[];
  team_h_player: teamPlayer[];
}

type teamPlayer = {
  element: number;
  captain_count: number;
  picked_count: number;
  gameWeekPoints: number | null;
  name: string;
  web_name: string;
  owned_by: IFplManager[];
  captained_by: IFplManager[];
  is_owned_by_current_manager: boolean;
};

/** useQuery hook to fetch Player Ownership for fixtures results page*/
export const useGetPlayerOwnershipFixture = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useUserContext();

  const fgw = searchParams.get(QUERY_PARAMS.FUTURE_GAME_WEEK)
    ? searchParams.get(QUERY_PARAMS.FUTURE_GAME_WEEK)
    : searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK);

  const queryKey = [
    params[PATH_PARAMS.LEAGUE_ID]!,
    params[PATH_PARAMS.FIXTURES_ID]!,
    user?.teamId,
    fgw,
  ];

  const getFixtures = async (queryKey: any[]) => {
    if (!queryKey.every((item) => item !== undefined)) return;

    const { data } = await axiosInstance.get<IPlayerOwnershipFixture>(
      FPL_CHAMP_ENDPOINTS.PLAYER_OWNERSHIP_FIXTURE(
        params[PATH_PARAMS.LEAGUE_ID]!,
        params[PATH_PARAMS.FIXTURES_ID]!,
        user?.teamId!,
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
        fgw!
      )
    );

    return data;
  };

  const { data: playerOwnership } = useQuery({
    queryFn: ({ queryKey }) => getFixtures(queryKey),
    queryKey: queryKey,
  });

  return { playerOwnership };
};
