import { AuthApiError, AuthSessionMissingError } from "@supabase/supabase-js";

export function handleErrors(err: any) {
  let errorResponse = {
    message: "An unexpected error occurred",
    details: {},
    statusCode: 500,
  };

  if (err instanceof AuthApiError || err instanceof AuthSessionMissingError) {
    errorResponse.message = err.message;
    errorResponse.details = err;
    errorResponse.statusCode = err.status;
  }

  return errorResponse;
}
