import { CircularProgress, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import logo from "../assets/identity/logo.svg";
import { bgColor } from "../config/theme";
import { LOADING_SCREEN_CONTENT } from "../constants/global.constants";

/**
 * LoadingScreen component displays a centered overlay while the user session is being established.
 * It's typically rendered on the initial app load when the `user` object is undefined.
 * Once the session is settled (user object is set or remains null), the loading screen is hidden.
 */

export const LoadingScreen = () => {
  return (
    <Stack css={loadingScreenContainerStyles}>
      <img src={logo} />
      <Typography variant="h1">{LOADING_SCREEN_CONTENT.TITLE}</Typography>
      <CircularProgress color="secondary" />
    </Stack>
  );
};

const loadingScreenContainerStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: ${bgColor};
`;
