import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useQuery } from "react-query";
import { useUserContext } from "../../context/UserContext";
import { IFplManager } from "../types/types";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";

export interface IMiniLeagueMostHurtfulRes {
  team: {
    name: string;
    short_name: string;
    team_id: number;
  };
  name: {
    web_name: string;
    first_name: string;
    last_name: string;
  };
  position: string;
  count: number;
  shirtLink: string;
  owned_by: IFplManager[];
  ownedPercentage: string;
  rank: number;
}

/**
 * fetch mini league captains data if history is true fetch standing table history view
 * if history is false fetch captains table and captains cards
 */

export const useGetMiniLeagueMostHurtfulTable = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useUserContext();
  const { leagues } = useGlobalInfoContext();

  const queryKey = [
    {
      leagueId: params[PATH_PARAMS.LEAGUE_ID]!,
      gw: searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
      managerId: user?.teamId,
      leagues,
    },
  ];

  const queryFunction = async () => {
    if (
      user &&
      user.teamId &&
      params[PATH_PARAMS.LEAGUE_ID] &&
      searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK) &&
      leagues.map((l) => l.id).includes(Number(params[PATH_PARAMS.LEAGUE_ID])) // check if league if a user league for the eplPlayerSummary Modal
    ) {
      const { data } = await axiosInstance.get<IMiniLeagueMostHurtfulRes[]>(
        FPL_CHAMP_ENDPOINTS.MINI_LEAGUE_MOST_HURTFUL(
          params[PATH_PARAMS.LEAGUE_ID]!,
          searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
          user.teamId
        )
      );

      return data;
    }
  };

  const {
    isLoading,
    data: mostHurtfulPlayer,
    isFetching,
  } = useQuery({
    queryKey: queryKey,
    queryFn: queryFunction,
  });

  return {
    mostHurtfulPlayerCardData: mostHurtfulPlayer?.slice(0, 3),
    mostHurtfulPlayerTableData: mostHurtfulPlayer,
    isLoading,
    isFetching,
  } as const;
};
