import * as yup from "yup";
import { AUTH_ERROR_MESSAGES } from "../constants/auth-pages.constants";
import { CONTACT_US_ERROR_MESSAGES } from "../constants/contact-us-page.constants";

const name = yup.string().required(AUTH_ERROR_MESSAGES.NAME.REQUIRED);
const email = yup
  .string()
  .email(AUTH_ERROR_MESSAGES.EMAIL.INVALID_FORMAT)
  .required(AUTH_ERROR_MESSAGES.EMAIL.REQUIRED);

const password = yup
  .string()
  .min(8, AUTH_ERROR_MESSAGES.PASSWORD.MIN_LENGTH)
  .max(72, AUTH_ERROR_MESSAGES.PASSWORD.MAX_LENGTH)
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])/,
    AUTH_ERROR_MESSAGES.PASSWORD.COMPLEX_PASSWORD
  )
  .required(AUTH_ERROR_MESSAGES.PASSWORD.REQUIRED);

const confirm_password = yup
  .string()
  .oneOf([yup.ref("password")], AUTH_ERROR_MESSAGES.CONFIRM_PASSWORD.NOT_MATCH)
  .required(AUTH_ERROR_MESSAGES.CONFIRM_PASSWORD.REQUIRED);

const policy = yup
  .boolean()
  .oneOf([true], AUTH_ERROR_MESSAGES.POLICY.CHECKED)
  .required();

const message = yup
  .string()
  .min(50, CONTACT_US_ERROR_MESSAGES.MESSAGE.MIN)
  .max(2000, CONTACT_US_ERROR_MESSAGES.MESSAGE.MAX)
  .required(CONTACT_US_ERROR_MESSAGES.MESSAGE.REQUIRED);

const title = yup.string().required(CONTACT_US_ERROR_MESSAGES.TITLE.REQUIRED);

/* YUP Schemas */
export const RegisterYupSchema = yup.object().shape({
  name,
  email,
  password,
  confirm_password,
  policy,
});
export type RegisterFormType = yup.InferType<typeof RegisterYupSchema>;

export const SignInYupSchema = yup.object().shape({
  email,
  password: yup.string().required(AUTH_ERROR_MESSAGES.PASSWORD.REQUIRED),
});
export type SignInFormType = yup.InferType<typeof SignInYupSchema>;

/** Team id validation */
const team_id = yup.number().typeError(AUTH_ERROR_MESSAGES.TEAM_ID.NUMBER);
export const TeamDetailsYupSchema = yup.object().shape({
  team_id: team_id.required(AUTH_ERROR_MESSAGES.TEAM_ID.REQUIRED),
});
export type TeamDetailsType = yup.InferType<typeof TeamDetailsYupSchema>;

export const TeamIdYupDemo = yup.object().shape({
  team_id: team_id,
});
export type TeamIdYupDemoType = yup.InferType<typeof TeamIdYupDemo>;

export const forgotPasswordYupSchema = yup.object().shape({
  email,
});
export type forgotPasswordFormType = yup.InferType<
  typeof forgotPasswordYupSchema
>;

export const ResetPasswordYupSchema = yup.object().shape({
  password,
  confirm_password,
});
export type ResetPasswordPasswordFormType = yup.InferType<
  typeof ResetPasswordYupSchema
>;

export const contactUsYupSchema = yup.object().shape({
  email,
  message,
  title,
});
export type ContactFormType = yup.InferType<typeof contactUsYupSchema>;
