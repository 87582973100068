import { darkGray } from "../../config/theme";
import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { useGetFixtures } from "../../utils/hooks/useGetFixtures";
import { PATH_PARAMS } from "../../config/routes";
import { useParams } from "react-router-dom";
import { Button } from "../../components";
import { FIXTURES_RESULTS } from "../../constants/fixtures-pages.constants";
import { useGetUrl } from "../../utils/hooks/useGetUrl";

/** FixtureOverviewPage component Displays match stats or a message if the match has not started. */
export const FixtureOverviewPage = () => {
  const urlParams = useParams();

  const { fixturesData } = useGetFixtures();
  const fixtureData =
    fixturesData &&
    fixturesData.fixtures.find(
      (fixture) => fixture.id === Number(urlParams[PATH_PARAMS.FIXTURES_ID])
    );

  if (fixtureData && !fixtureData.started) {
    return <MatchNotStarted />;
  }

  return (
    <Stack>
      {fixtureData?.modified_stats.map((stat) => (
        <Stack key={stat.identifier}>
          <Typography css={gameEventTitleStyle}>{stat.identifier}</Typography>

          <Stack css={gameEventPlayersContainerStyles}>
            <ul css={playerNameStyles}>
              {stat.teamH.map((item) => (
                <li
                  key={item.element}
                >{`${item.epl_player_name} (${item.value})`}</li>
              ))}
            </ul>

            <span css={gameEventPlayersDividerStyles} />

            <ul css={playerNameStyles}>
              {stat.teamA.map((item) => (
                <li
                  key={item.element}
                >{`${item.epl_player_name} (${item.value})`}</li>
              ))}
            </ul>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

// Component for Match not started message
const MatchNotStarted = () => {
  const urlParams = useParams();
  const { getFixtureResultsOwnershipPath } = useGetUrl();
  return (
    <Stack css={matchNotStartedContainerStyles}>
      <Typography css={matchNotStartedTitleStyles}>
        {FIXTURES_RESULTS.NO_OVERVIEW.TITLE}
      </Typography>
      <Typography css={matchNotStartedDescriptionStyles}>
        {FIXTURES_RESULTS.NO_OVERVIEW.DESCRIPTION}
      </Typography>
      <Button
        to={getFixtureResultsOwnershipPath(urlParams[PATH_PARAMS.FIXTURES_ID]!)}
        button="gradient"
      >
        {FIXTURES_RESULTS.NO_OVERVIEW.BTN}
      </Button>
    </Stack>
  );
};

const gameEventTitleStyle = css`
  text-transform: capitalize;
  text-align: center;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  margin-block: 0.5rem;
`;

const gameEventPlayersContainerStyles = css`
  flex-direction: row;
  margin-bottom: 1rem;
`;

const gameEventPlayersDividerStyles = css`
  width: 0.1rem;
  background-color: ${darkGray};
`;

const playerNameStyles = css`
  text-align: center;
  flex: 1;
`;

// Styles
const matchNotStartedContainerStyles = css`
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 400px;
  margin-inline: auto;
  margin-top: 2rem;
`;

const matchNotStartedTitleStyles = css`
  font-size: clamp(1.5rem, 5vw, 2.2rem);
  font-weight: 700;
`;

const matchNotStartedDescriptionStyles = css`
  text-align: center;
`;
