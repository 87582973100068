export const RankIndicatorIcon = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0.295899L7.4641 6.2959L0.535897 6.2959L4 0.295899Z"
        fill={fill}
      />
    </svg>
  );
};
