import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { bgColor } from "../../../config/theme";
import { IEplPlayerTransferInfo } from "../../../utils/hooks/useGetLeagueTransfers";
import { Skeleton } from "../../../components";

interface IEplPlayerTransfer {
  eplPlayer: IEplPlayerTransferInfo;
  color: string;
}

/**
 * Renders a single player in the transfer summary table. This component shows
 * the player's logo, name, and total points for the current game week.
 */
export const EplPlayerTransfer = (props: IEplPlayerTransfer) => {
  return (
    <Stack css={transfersSummaryPlayerContainerStyles}>
      <img src={props.eplPlayer.shirt_logo} />
      <Stack css={transfersSummaryPlayerTitleStyles(props.color)}>
        <Typography variant="h1">{props.eplPlayer.name}</Typography>
        <Typography variant="h2">
          {props.eplPlayer.has_played
            ? props.eplPlayer.gw_total_points
            : "Calculating"}{" "}
          pts
        </Typography>
      </Stack>
    </Stack>
  );
};

export const EplPlayerTransferSkeleton = () => (
  <Stack css={transfersSummaryPlayerContainerStyles}>
    <Skeleton
      component={"span"}
      css={css`
        flex: 0.5;
        aspect-ratio: 1/1 !important;
        background-color: ${bgColor};
        border-radius: 9999px;
        max-width: 4rem;
      `}
    />
    <Stack
      css={css`
        flex: 1;
      `}
    >
      <Skeleton
        component={"div"}
        css={css`
          flex: 1;
          margin-bottom: 0.2rem;
          background-color: ${bgColor};
          max-width: 10rem;
          width: 100%;
        `}
      />
      <Skeleton
        component={"div"}
        css={css`
          flex: 1;
          margin-bottom: 0.15rem;
          background-color: ${bgColor};
          width: 75%;
          max-width: 7rem;
        `}
      />
    </Stack>
  </Stack>
);

const transfersSummaryPlayerTitleStyles = (color: string) => css`
  h1 {
    font-size: clamp(1rem, 2vw, 1.2rem);
  }

  h2 {
    font-size: clamp(0.875rem, 1.8vw, 1rem);
    font-weight: 600;
    color: ${color};
  }
`;

const transfersSummaryPlayerContainerStyles = css`
  flex: 1;
  & * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  img {
    border-radius: 9999px;
    border: 0.3rem solid ${bgColor};
    background-color: ${bgColor};
    max-width: clamp(2rem, 13vw, 3.5rem);
    aspect-ratio: 1/1;
  }
`;
