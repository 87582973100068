export const CupIcon = ({
  fill = "#DDDEDF",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0005 1.95597C17.742 2.09747 18.1816 2.25299 18.5614 2.72083C19.0372 3.30673 19.012 3.93998 18.9617 5.20647C18.781 9.7595 17.7606 15.5 12.0007 15.5C6.2407 15.5 5.22032 9.7595 5.03956 5.20647C4.98928 3.93998 4.96414 3.30673 5.43986 2.72083C5.91557 2.13494 6.48494 2.03887 7.62367 1.84674C8.74773 1.65709 10.2171 1.5 12.0007 1.5C12.7189 1.5 13.3862 1.52548 14.0005 1.56829"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19 4.5L19.9486 4.81621C20.9387 5.14623 21.4337 5.31124 21.7168 5.70408C22 6.09692 22 6.61873 21.9999 7.66234V7.73487C21.9999 8.59561 21.9999 9.02598 21.7927 9.37809C21.5855 9.7302 21.2093 9.9392 20.4569 10.3572L17.5 12"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M5.40033 4.30078L4.45171 4.61699C3.46165 4.94701 2.96662 5.11202 2.68349 5.50486C2.40035 5.8977 2.40036 6.41951 2.40039 7.46312V7.53565C2.40042 8.39639 2.40043 8.82676 2.60762 9.17887C2.8148 9.53098 3.19102 9.73998 3.94344 10.158L6.90033 11.8008"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M12 16.5V18.5"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.5 21.5H8.5L8.83922 19.8039C8.93271 19.3365 9.34312 19 9.8198 19H14.1802C14.6569 19 15.0673 19.3365 15.1608 19.8039L15.5 21.5Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 21.5H6"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
