import logo from "../../assets/identity/logo_name.svg";
import megaPhone from "../../assets/coming-soon-page/mega-phone.svg";
import { Stack, Typography } from "@mui/material";
import { COMING_SOON_PAGE } from "../../constants/coming-soon-page-constants/comingSoon.constants";
import { css } from "@emotion/react";
import { secondaryColor } from "../../config/theme";
import { container, mq } from "../../config/styles";
import { Helmet } from "react-helmet";
import { SubscribeInput } from "./components/SubscribeInput";

/** Renders the coming soon page */
export const ComingSoonPage = () => {
  return (
    <Stack justifyContent={"space-between"} css={root}>
      <Helmet>
        <title>{COMING_SOON_PAGE.META.TITLE}</title>
      </Helmet>
      <img src={logo} alt="fpl champ logo" css={logoStyle} />

      <Stack css={contentContainer}>
        <Stack justifyContent={"center"} css={mainStyle}>
          <Typography variant="h1">{COMING_SOON_PAGE.TITLE}</Typography>
          <Typography variant="h2">{COMING_SOON_PAGE.SUBTITLE}</Typography>
          <SubscribeInput label={COMING_SOON_PAGE.INPUT_LABEL} />
        </Stack>

        <Stack justifyContent={"center"}>
          <img src={megaPhone} alt="mega phone" css={megaPhoneImageStyle} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const contentContainer = css`
  /* gap: 2rem; */
  ${mq["sm"]} {
    gap: 2rem;
    flex-direction: row;
  }

  ${mq["md"]} {
    flex-direction: column;
  }

  ${mq["lg"]} {
    flex-direction: row;
  }
`;

const root = css`
  ${container}/* max-height: 100vh; */
`;

const logoStyle = css`
  max-width: 19.375rem;
`;

// const titleStyle = css`
//   font-size: 3em !important;
//   margin-top: 12px;
// `;

// const subtitleStyle = css`
//   font-size: 2em !important;
// `;

const mainStyle = css`
  h1 {
    color: ${secondaryColor};
    font-size: clamp(2.5rem, 4vw, 2.5rem);
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 2.5rem;
  }

  h2 {
    font-size: clamp(1.5rem, 5vw, 3rem);
    font-weight: bold;
    margin-bottom: 4rem;
  }
`;

const megaPhoneImageStyle = css`
  width: 100%;
  ${mq["sm"]} {
    width: 15rem;
  }
  ${mq["md"]} {
    width: 100%;
  }
  ${mq["lg"]} {
    width: 20rem;
  }
  ${mq["xl"]} {
    width: 25rem;
  }
`;
