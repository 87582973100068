import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { IconButton, Stack } from "@mui/material";
import { css } from "@emotion/react";
import { transition_all } from "../config/styles";
import { secondaryColor } from "../config/theme";

interface IAppPagination {
  onBackClick(): void;
  onNextClick(): void;
  nextBtnDisabled: boolean;
  backBtnDisabled: boolean;
  pageNumber: number;
  accentColor?: string;
  pageCount?: number;
}

/**
 * A reusable pagination component for the app.
 * used in AppTable.tsx and the transfers page
 */

export const AppPagination = (props: IAppPagination) => {
  return (
    <Stack css={paginationStyle(props.accentColor)}>
      <IconButton onClick={props.onBackClick} disabled={props.backBtnDisabled}>
        <ArrowForwardRoundedIcon sx={{ transform: "rotate(180deg)" }} />
      </IconButton>

      {/* Page Number */}
      <>
        Page {props.pageNumber}{" "}
        {props.pageCount ? `of ${props.pageCount}` : null}
      </>

      <IconButton onClick={props.onNextClick} disabled={props.nextBtnDisabled}>
        <ArrowForwardRoundedIcon />
      </IconButton>
    </Stack>
  );
};

const paginationStyle = (color: string | undefined) => css`
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  font-size: 0.875rem;
  background-color: transparent;

  button {
    ${transition_all}
    color: white;
    border: 2px solid white;
    color: ${color || secondaryColor};
    border: 2px solid ${color || secondaryColor};
    padding: 0.25rem;
    border-radius: 0.5rem;
  }

  button:disabled {
    color: #535360;
    border: 2px solid #535360;
  }
`;
