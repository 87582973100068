import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import tShirtIcon from "../../../assets/landing-page/t-shirt-icon.svg";
import card5Image from "../../../assets/landing-page/card-5-img.png";
import { ExtraFeaturesCardContainer } from "./ExtraFeaturesCardContainer";
import { ExtraFeaturesIconContainer } from "./ExtraFeaturesIconContainer";
import { mq } from "../../../config/styles";

/**
 * A component that renders a card with a heading, subheading, and icon, specific
 * to the fifth card in the ExtraFeaturesSection of the landing page.
 */
export const ExtraFeaturesCard5 = () => {
  return (
    <ExtraFeaturesCardContainer>
      <Stack css={titlesStyles}>
        <Typography variant="h1">
          <ExtraFeaturesIconContainer icon={tShirtIcon} />
          {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD5.TITLE}
        </Typography>
        <Typography variant="h2">
          {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD5.SUBTITLE}
          <img src={card5Image} css={card5ImageStyles} />
        </Typography>
      </Stack>
    </ExtraFeaturesCardContainer>
  );
};

const titlesStyles = css`
  flex: 1;
  height: 100%;
  padding-top: var(--spacing);
  padding-left: var(--spacing);
  gap: var(--spacing);

  h1 {
    flex: 1;
    display: flex;
    gap: 0.5rem;
    align-items: start;
    font-size: clamp(1.5rem, 3vw, 1.5rem);
    font-weight: 600;
  }

  h2 {
    display: flex;
    align-items: start;
    font-size: clamp(1.2rem, 2.5vw, 1.25rem);
    color: #98a2b3;
  }
`;

const card5ImageStyles = css`
  align-self: center;
  width: 50%;

  ${mq["md"]} {
    width: 80%;
  }
`;
