import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { AppSimpleTable, AppSimpleTableSkeleton } from "../../../components";
import { IEplPlayerSummary } from "../../../utils/hooks/useEplPlayerSummary";
import { eplPlayerSummaryFixture } from "../../../utils/tableColumns/eplPLayerSummaryColumns";
import { EPL_PLAYER_SUMMARY } from "../../../constants/view-team-pages.constants";

interface IEplPlayerSummaryFixtureTable {
  fixtureRows: IEplPlayerSummary["summary"]["fixtures"] | undefined;
}

/**
 * EplPlayerHistoryTable Component Displays current season fixtures for a player.
 */
export const EplPlayerSummaryFixtureTable = (
  props: IEplPlayerSummaryFixtureTable
) => {
  return (
    <Stack paddingInline={"1rem"}>
      <Typography css={titleStyles}>
        {EPL_PLAYER_SUMMARY.TABLE_TITLES.THIS_SEASON}
      </Typography>

      {props.fixtureRows ? (
        <AppSimpleTable
          getRowId={(row) => row.code}
          hideFooter
          rowHeight={40}
          columns={eplPlayerSummaryFixture}
          rows={props.fixtureRows || []}
        />
      ) : (
        <AppSimpleTableSkeleton />
      )}
    </Stack>
  );
};

const titleStyles = css`
  font-family: Poppins;
  font-size: clamp(1.2rem, 3vw, 2rem);
  font-weight: 700;
`;
