import Joyride, { Step } from "react-joyride";
import { ToolTipContent } from "./AppTooltip";

interface IAppJoyride {
  steps: Step[];
  title: string;
  subTitle: string;
  run: boolean;
}

/** Implements app joyride with base styles and config */
export const AppJoyride = (props: IAppJoyride) => {
  return (
    <Joyride
      steps={props.steps}
      disableOverlayClose
      locale={{ close: "Cool" }}
      scrollOffset={100}
      run={props.run}
      tooltipComponent={(joyrideProps) => (
        <ToolTipContent
          title={props.title}
          subTitle={props.subTitle}
          handleBtnClick={(e) => {
            joyrideProps.closeProps.onClick(e);
          }}
        />
      )}
    />
  );
};
