export const PRIVACY_PAGE_CONTENT = {
  META: {
    TITLE: "Privacy Policy",
  },
  TITLE: "Privacy Policy for FPL Champ",
  INTRODUCTION: {
    TITLE: "Introduction",
    INTRO:
      "We at FPL Champ are committed to protecting the privacy of our users. This Privacy Policy explains what information we collect, how we use it, and your rights regarding your information.",
    INFO_WE_COLLECT_SECTION: {
      TITLE: "Information We Collect",
      INTRO:
        "We collect the following types of information when you use our web app, FPL Champ:",
      INFO_WE_COLLECT_LIST: [
        {
          TITLE: "Account Information:",
          PARAGRAPH:
            "When you register for an account, we may collect personal information such as your username, email address, and password.",
        },
        {
          TITLE: "Usage Data:",
          PARAGRAPH:
            "We collect anonymous usage data about your interactions with the app. This may include information about the features you use, the frequency of your use, your performance in fantasy leagues, and your device information (type, operating system).",
        },
      ],
    },
    EXCLAMATION_SECTION: {
      TITLE: "exclamation",
      EXCLAMATION_LIST: [
        {
          TITLE: "Cookies and Tracking Technologies:",
          PARAGRAPH:
            "We may use cookies and other tracking technologies to store information about your preferences and track your activity within the app. You can control or disable cookies through your browser settings.",
        },
      ],
    },
  },
  HOW_WE_USE_YOUR_INFO: {
    TITLE: "How We Use Your Information",
    INTRO: "We use the information we collect for the following purposes:",
    HOW_WE_USE_YOUR_INFO_LIST: [
      "To provide and improve the functionality and user experience of the app, including personalized recommendations and content.",
      "To personalize your experience based on your usage patterns and performance data.",
      "To send you personalized communication (if you opt-in), such as league updates, news, and tips.",
      "To conduct analytics to understand user behavior and improve the app.",
      "To comply with legal and regulatory requirements.",
      "We will not share your personal information with third parties without your consent, except in the following cases:",
      "To service providers who help us operate the app.",
      "To comply with legal or regulatory requirements.",
      "In the event of a merger or acquisition.",
    ],
  },
  DATA_SECURITY: {
    TITLE: "Data Security",
    INTRO:
      "We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure. We cannot guarantee the security of your information.",
  },
  YOUR_CHOICE: {
    TITLE: "Your Choices",
    YOUR_CHOICES_LIST: [
      {
        TITLE: "Access and Update Your Information:",
        PARAGRAPH:
          "You can access, update, or delete your personal information through your account settings.",
      },
      {
        TITLE: "Opt-Out of Marketing Communications:",
        PARAGRAPH:
          "You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in the emails we send you, or by adjusting your communication preferences in your account settings.",
      },
    ],
  },
  CHILDREN_PRIVACY: {
    TITLE: "Children's Privacy",
    INTRO:
      "Our app is not directed towards children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us.",
  },
  CHANGES_TO_POLICY: {
    TITLE: "Changes to This Policy",
    INTRO:
      "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.",
  },
  CONTACT: {
    TITLE: "Contact Us",
    INTRO:
      "If you have any questions about this Privacy Policy, please contact us at contact@fplchamp.com.",
  },
};

export const TERMS_OF_SERVICE_PAGE_CONTENT = {
  META: {
    TITLE: "Terms of services",
  },
  TITLE: "Terms of Service for FPL Champ",
  CONTENT: [
    {
      TITLE: "Introduction",
      PARAGRAPH:
        "These Terms of Service govern your access to and use of the FPL Champ web application, owned and operated by FPL Champ. By accessing or using the App, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access or use the App.",
    },
    {
      TITLE: "User Accounts",
      CONTENT_LIST: [
        "You may be required to create an account to access certain features of the App.",
        "You are responsible for maintaining the confidentiality of your account information and password.",
        "You are responsible for all activity that occurs under your account.",
        "You agree to notify us immediately of any unauthorized use of your account or any other security breach.",
      ],
    },
    {
      TITLE: "User Content",
      CONTENT_LIST: [
        "You are responsible for any content you submit to the App.",
        "You represent and warrant that you have all rights to submit User Content and that such User Content does not infringe on any third-party rights.",
        "We reserve the right to remove or modify any User Content at our sole discretion.",
      ],
    },
    {
      TITLE: "Acceptable Use",
      CONTENT_LIST: [
        "You agree to use the App in a lawful and respectful manner.",
        "You agree not to use the App for any illegal or unauthorized purpose.",
        "You agree not to violate any applicable laws or regulations.",
        "You agree not to interfere with or disrupt the App or its servers.",
        "You agree not to transmit any viruses or other harmful code through the App.",
      ],
    },
    {
      TITLE: "Intellectual Property",
      CONTENT_LIST: [
        "The App and all content and materials included therein (including text, graphics, logos, images, and software) are protected by intellectual property rights.",
        "You may not use any content or materials from the App without our prior written permission.",
      ],
    },
    {
      TITLE: "Disclaimers",
      CONTENT_LIST: [
        'The App is provided "as is" and without warranties of any kind, express or implied.',
        "We disclaim all warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.",
        "We do not warrant that the App will be uninterrupted or error-free.",
        "We do not warrant that the App will be secure from unauthorized access, viruses, or other harmful components.",
      ],
    },
    {
      TITLE: "Termination",
      CONTENT_LIST: [
        "We may terminate your access to the App at any time, for any reason, without notice.",
        "You may terminate your account at any time.",
      ],
    },
    {
      TITLE: "Entire Agreement",
      PARAGRAPH:
        "These Terms constitute the entire agreement between you and us regarding your use of the App.",
    },
    {
      TITLE: "Updates to Terms",
      PARAGRAPH:
        "We may update these Terms at any time by posting the revised terms on the App.  Your continued use of the App following the posting of revised Terms means that you accept and agree to the changes.",
    },
    {
      TITLE: "Contact Us",
      PARAGRAPH:
        "If you have any questions about these Terms, please contact us at contact@fplchamp.com.",
    },
  ],
};
