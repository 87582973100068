import { css } from "@emotion/react";
import { Box } from "@mui/material";
import { RankIndicatorIcon } from "./icons";
import { redColor, secondaryColor } from "../config/theme";

interface IRankIndicator {
  lastRank: number;
  rank: number;
}

/*
 * Return an icon indicating id rank change
 * rise (green up arrow), fall (red down arrow) or Same (Gray Circle)
 */
export const RankIndicator = (props: IRankIndicator) => {
  if (props.lastRank > props.rank)
    return <RankIndicatorIcon fill={secondaryColor} />;
  if (props.lastRank === props.rank)
    return <Box component={"span"} css={grayCircleStyles} />;
  if (props.lastRank < props.rank)
    return <RankIndicatorIcon fill={redColor} css={redArrowStyles} />;
};

const grayCircleStyles = css`
  border-radius: 999px;
  width: 0.5rem;
  aspect-ratio: 1/1;
  background-color: #8e8e8e;
`;

const redArrowStyles = css`
  rotate: 180deg;
`;
