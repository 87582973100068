import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import Swoosh from "../../../assets/landing-page/swoosh.svg";
import { LandingPageHeader } from "./LandingPageHeader";
import { mq, primaryGradient } from "../../../config/styles";

import highlightImg1 from "../../../assets/landing-page/highlights-img-1.png";
import highlightImg2 from "../../../assets/landing-page/highlights-img-2.png";
import highlightImg3 from "../../../assets/landing-page/highlights-img-3.png";
import highlightImg4 from "../../../assets/landing-page/highlights-img-4.png";

import highlightWrappingImg1 from "../../../assets/landing-page/highlights-wrapping-img-1.svg";
import highlightWrappingImg2 from "../../../assets/landing-page/highlights-wrapping-img-2.svg";
import highlightWrappingImg3 from "../../../assets/landing-page/highlights-wrapping-img-3.svg";

const highlightsImages = [
  {
    img: highlightImg1,
    wrappingImg: highlightWrappingImg1,
    wrappingImageExtraStyles: css`
      ${mq["md"]} {
        top: unset !important;
        bottom: 0 !important;
        translate: -50% 0 !important;
      }
    `,
  },
  {
    img: highlightImg2,
    wrappingImg: highlightWrappingImg1,
    wrappingImageExtraStyles: css`
      transform: rotateY(180deg);
      ${mq["md"]} {
        ${mq["md"]} {
          top: 0 !important;
          translate: -50% 0 !important;
        }
      }
    `,
  },
  {
    img: highlightImg3,
    wrappingImg: highlightWrappingImg2,
    wrappingImageExtraStyles: css``,
  },
  {
    img: highlightImg4,
    wrappingImg: highlightWrappingImg3,
    wrappingImageExtraStyles: css``,
  },
];

/**
 * A section on the landing page that showcases the highlights of the
 * app. This includes a title, subtitle, and 4 highlights with images.
 */
export const HighlightsSection = () => {
  return (
    <div>
      <Stack css={highlightsSectionTitleContainerStyles}>
        <Typography variant="h2">
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_SECTION_CONTENT.SURTITLE}
        </Typography>
        <LandingPageHeader>
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_SECTION_CONTENT.TITLE}
          <img src={Swoosh} alt="swoosh" />
        </LandingPageHeader>
        <Typography>
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_SECTION_CONTENT.SUBTITLE}
        </Typography>
      </Stack>

      <Stack css={highlighRootContainerStyles}>
        {LANDING_PAGE_CONTENT.HIGHLIGHTS_SECTION_CONTENT.HIGHLIGHTS.map(
          (highlight, index) => (
            <Stack key={index} css={highlighContainerStyles}>
              <Stack css={highlightsTitleStyles}>
                <img
                  src={highlightsImages[index].wrappingImg}
                  css={highlightsImages[index].wrappingImageExtraStyles}
                />
                <Typography variant="h2">{highlight.SURTITLE}</Typography>
                <Typography variant="h1">{highlight.TITLE}</Typography>
                <Typography variant="h3">{highlight.SUBTITLE}</Typography>
              </Stack>
              <img
                src={highlightsImages[index].img}
                css={highlightsImageStyles}
              />
            </Stack>
          )
        )}
      </Stack>
    </div>
  );
};

const highlightsSectionTitleContainerStyles = css`
  align-items: center;
  justify-content: center;
  padding-inline: 1rem;
  text-align: center;

  h2 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
  }

  h1 {
    margin-bottom: 2.5rem;
    position: relative;
    isolation: isolate;
    img {
      position: absolute;
      top: 100%;
      left: 50%;
      translate: -50%;
      width: 85%;
      z-index: -1;
    }
  }

  p {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    max-width: 50rem;
  }
`;

const highlighRootContainerStyles = css`
  margin-top: 3rem;
  gap: 3rem;
`;

const highlightsTitleStyles = css`
  position: relative;
  isolation: isolate;
  justify-content: center;
  overflow: hidden;

  h2 {
    ${primaryGradient}
    color: transparent;
    background-clip: text;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    text-transform: capitalize;
    font-weight: 500;
  }

  h1 {
    font-size: clamp(1.5rem, 3vw, 1.8rem);
    font-weight: 600;
  }

  h3 {
    font-size: clamp(0.875rem, 2.5vw, 1.1rem);
  }

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 100%;
    z-index: -1;
  }

  ${mq["md"]} {
    height: 30rem;
    padding-inline: 5rem;
  }
`;

const highlighContainerStyles = css`
  & > div {
    height: 20rem;
    padding-inline: 1rem;
  }

  &:nth-of-type(3) > div,
  &:nth-of-type(4) > div {
    height: 12rem;
  }

  &:nth-of-type(2n + 1) > div {
    padding-right: 2rem;
  }

  &:nth-of-type(2n) > div {
    padding-left: 3rem;
  }

  ${mq["md"]} {
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    gap: 4rem;

    & > div {
      height: 24rem !important;
    }

    &:nth-of-type(2n) {
      padding-left: 5rem;
    }

    &:nth-of-type(2n + 1) {
      padding-right: 5rem;
      flex-direction: row;
    }

    &:nth-of-type(2n + 1) > div {
      padding-right: 4rem;
    }

    &:nth-of-type(2n) > div {
      padding-left: 6rem;
    }
  }
`;

const highlightsImageStyles = css`
  padding: 1rem;
  width: 100%;
  ${mq["md"]} {
    width: 40%;
  }
`;
