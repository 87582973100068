import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { useQuery } from "react-query";

export interface IEplPlayerTransferInfo {
  name: string;
  image: string;
  has_played: boolean;
  gw_total_points: number;
  team_name: string;
  team_logo: string;
  shirt_logo: string;
}

export interface IGetLeagueTransfersRes {
  has_next: boolean;
  leagueTransfers: {
    entry: number;
    gw_total_points: number;
    fpl_team_name: string;
    fpl_manager_name: string;
    total_net_points: number;
    transfers: {
      [event: string]: {
        active_chip: null | string;
        event_transfers_cost: number;
        net_points: number;
        event: number;
        event_transfers: {
          element_in: number;
          element_in_cost: number;
          element_out: number;
          element_out_cost: number;
          entry: number;
          event: number;
          time: string;
          element_in_info: IEplPlayerTransferInfo;
          element_out_info: IEplPlayerTransferInfo;
        }[];
      };
    };
  }[];
}

/** useQuery hook for getting mini league transfers */
export const useGetLeagueTransfers = (pageNumber: number) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const queryKey = [
    "useGetLeagueTransfers",
    params[PATH_PARAMS.LEAGUE_ID]!,
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
    pageNumber,
  ];

  const getTransfers = async (
    queryKey: (string | number | null | undefined)[]
  ) => {
    if (queryKey.some((item) => item === undefined || item === null)) return;

    const { data } = await axiosInstance.get<IGetLeagueTransfersRes>(
      FPL_CHAMP_ENDPOINTS.GET_LEAGUE_TRANSFERS_URL(
        params[PATH_PARAMS.LEAGUE_ID]!,
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
        pageNumber
      )
    );

    return data;
  };

  const { data: leagueTransfers } = useQuery({
    queryFn: ({ queryKey }) => getTransfers(queryKey),
    queryKey: queryKey,
  });

  return { leagueTransfers };
};
