export const TransfersIcon = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 13L27 15V20H24V28C24 28.2652 23.8946 28.5196 23.7071 28.7071C23.5196 28.8946 23.2652 29 23 29H13C12.7348 29 12.4804 28.8946 12.2929 28.7071C12.1054 28.5196 12 28.2652 12 28V20H9V15L15 13C15 13.7956 15.3161 14.5587 15.8787 15.1213C16.4413 15.6839 17.2044 16 18 16C18.7956 16 19.5587 15.6839 20.1213 15.1213C20.6839 14.5587 21 13.7956 21 13Z"
        stroke={fill}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.86412 1.05725C9.65072 0.860964 9.65072 0.54272 9.86412 0.346434C10.0776 0.150147 10.4236 0.150147 10.637 0.346434L11.8762 1.48618L11.8834 1.49286C12.3069 1.8854 12.3059 2.51946 11.8804 2.91089L10.6397 4.05204C10.4264 4.2482 10.0806 4.2482 9.86739 4.05204C9.6541 3.85588 9.6541 3.53783 9.86739 3.34167L10.5659 2.69918H2.38256C2.0813 2.69918 1.83707 2.92304 1.83707 3.19917V6.69912C1.83707 6.97527 1.59285 7.19912 1.29158 7.19912C0.99032 7.19912 0.746094 6.97527 0.746094 6.69912V3.19917C0.746094 2.37076 1.47877 1.6992 2.38256 1.6992H10.5621L9.86412 1.05725Z"
        fill={fill}
      />
      <path
        d="M11.6558 5.69921C11.6558 5.42307 11.4116 5.19922 11.1103 5.19922C10.809 5.19922 10.5648 5.42307 10.5648 5.69921V9.19916C10.5648 9.47531 10.3206 9.69915 10.0193 9.69915H1.83842L2.53637 9.05721C2.74977 8.86092 2.74977 8.54267 2.53636 8.34637C2.32295 8.15013 1.97695 8.15013 1.76354 8.34637L0.524363 9.48616L0.517173 9.49281C0.093562 9.88535 0.0945379 10.5194 0.520103 10.9108L1.7608 12.052C1.97408 12.2482 2.31986 12.2482 2.53313 12.052C2.74641 11.8558 2.74641 11.5378 2.53313 11.3416L1.83459 10.6991H10.0193C10.9231 10.6991 11.6558 10.0276 11.6558 9.19916V5.69921Z"
        fill={fill}
      />
    </svg>
  );
};
